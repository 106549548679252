import { Avatar, Button, CircularProgress, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import CustomizedSnackbars from "../../components/material-snackbars/index.js";
import { AbilityContext } from '../../config/ability-context';
import { browserHistory } from '../../helpers/history';
import Api from "../../services/api";
import axios from 'axios';
import { Overlay } from "../../styles/global.js";
import { Styles } from '../../styles/material-styles';
import { useSearchParams } from "react-router-dom";
import queryString from 'query-string';
//import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { jwtDecode } from 'jwt-decode' // import dependency
import qs from 'qs';
import { PapiroConsole } from '../../utils/papiroConsole';
import { resetLocalStorageVariables } from '../../utils/storageUtils';
import * as lastCalledsQueueActions from '../../redux/actions/last-calleds-queue';
import * as userSessionActions from '../../redux/actions/user-session';

class Authorize extends Component {

  constructor(props) {
    super(props);

    const { match } = this.props;
    let search = this.props.location.search;

    let params = new URLSearchParams(search);
    let param_code = params.get('code');
    let param_state = params.get('state');
    let url_redirect = params.get('redirect');
    let scope = params.get('scope');
    let uuid = params.get('uuid');
    localStorage.setItem('govbr_code', `${param_code}`)



    this.itemInitialState = {
      param_code: param_code,
      param_state: param_state,
      url_redirect: url_redirect,
      scope: scope,
      uuid: uuid,
    }; 

    this.state = {
      item: this.itemInitialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
    };

    this.auth = this.auth.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }

  auth = async () => {
    this.setState({ loading: true });
    let govbr_state = localStorage.getItem('govbr_state');
    
    //alert(govbr_state);
    //alert(this.state.item.param_state);
    //alert(this.state.item.param_code);
    //alert(this.state.item.url_redirect);
   // alert(this.state.item.scope);
    //alert(this.state.item.uuid);
    
    
    try {

        var ApiAxios = axios.create({
          baseURL: Api.apiurl(),
        });
        ApiAxios.defaults.headers.post['Accept'] = 'application/json';
        ApiAxios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

        if(this.state.item.param_code != null && this.state.item.scope != null ){
                  const data = {};
                  data.code=this.state.item.param_code;
                  data.scope=this.state.item.scope;
                  data.uuid=this.state.item.uuid;
                  

                  let config = {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Accept" : "application/json",
                        "Access-Control-Allow-Origin":"*",
                      }
                  };        
                  var u = Api.apiurl(); 
  
                  alert(u); 
                  ApiAxios.post(u+"/gmail/oauth2",qs.stringify(data), config)
                  .then(result => {
                      console.log(JSON.stringify(result));
      
                      this.setState({
                          loading: false,
                          openNotification: true,
                          notificationVariant: "success",
                          notificationMessage: "Autenticação realizada com sucesso!",
                      });
      
                  })
                  .catch(err => {
                      this.setState({
                          loading: false,
                          openNotification: true,
                          notificationVariant: "error",
                          notificationMessage: JSON.stringify(err.response)
                      });
                  });

        }
        else{
          if(govbr_state == this.state.item.param_state){


        
              let dataSet = new FormData();
              let secret="guvhEYTAySOTrjINWWK44cdiNJKUSoGijtaeyKONjzcM3HT70xWaRWeV4Mrb3l1-T24Rlmp91TNDYyIsmXzjVg";
              let client_id="speedmais.homologa.2do.mexx.ai";
          
              let redirecturi = 'https%3A%2F%2Fspeedmais.homologa.2do.mexx.ai%2Fpt%2Fauthorize';
              redirecturi = 'https://speedmais.homologa.2do.mexx.ai/pt/authorize';
              //redirecturi='https://speedmais.homologa.2do.mexx.ai';
              let codeverifier = localStorage.getItem('govbr_codeverifier');
              let code_challenge = localStorage.getItem('govbr_code_challenge');
              
              if(this.state.item.url_redirect != null){
                redirecturi = this.state.item.url_redirect;
              }

              dataSet.append('grant_type', "authorization_code");
              dataSet.append('code', this.state.item.param_code);
              dataSet.append('redirect_uri', redirecturi);
              dataSet.append('code_verifier', codeverifier);
              //Informação codificada em Base64, no seguinte formato: CLIENT_ID:CLIENT_SECRET (senha de acesso do serviço consumidor)(utilizar codificador para Base64 site externo para gerar codificação). A palavra Basic deve está antes da informação.

              let token = Buffer.from(client_id+':'+secret).toString('base64');
              let config = {
                  headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                      "Authorization" : "Basic " + token,
                      //"Access-Control-Allow-Origin":"*",
                  }
              };        
              const data = {};
              data.grant_type="authorization_code";
              data.code=this.state.item.param_code;
              data.redirect_uri=redirecturi;
              data.code_verifier=codeverifier;
              

              let url="https://sso.staging.acesso.gov.br";
              //console.log(dataSet);
              //console.log(config);
              await ApiAxios.post(url+"/token", qs.stringify(data), config)
              .then(result =>  {
                //console.log('result');
                //console.log(JSON.stringify(result.data));
                const user = jwtDecode(result.data.id_token); // decode your token here
                  localStorage.setItem('govbr_result', JSON.stringify(user));
                  //console.log(JSON.stringify(user));

                  this.setState({
                      loading: true,
                      openNotification: true,
                      notificationVariant: "info",
                      notificationMessage: "Autenticação realizada, recuperando autorização...",
                  });

                  /*
                      {
                              "access_token": "(Token de acesso a recursos protegidos do autenticador, bem como serviços do Login Único.)",
                              "id_token": "(Token de autenticação com informações básicas do usuário.)",
                              "token_type": "(O tipo do token gerado. Padrão: Bearer)",
                              "expires_in": "(Tempo de vida do token em segundos.)"
                      }                
                  */ 
                  let config = {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Accept" : "application/json",
                          //"Access-Control-Allow-Origin":"*",
                      }
                  };        
      
                  /* 
                  ApiAxios.get(url+"/jwk", config)
                      .then(result => {
                          console.log(JSON.stringify(result));
                          localStorage.setItem('govbr_claims', result);
          
                          this.setState({
                              loading: false,
                              openNotification: true,
                              notificationVariant: "success",
                              notificationMessage: "Autenticação realizada com sucesso!",
                          });
          
                      })
                      .catch(err => {
                          this.setState({
                              loading: false,
                              openNotification: true,
                              notificationVariant: "error",
                              notificationMessage: JSON.stringify(err.response)
                          });
                  });
                  */

                  this.props.addUserSession(null);

                 
                  let email = user.email.toString().toLowerCase();

                  let twoFactorAuthCode = localStorage.getItem('twoFactorAuthCode');
                  let userAcceptedLogged = localStorage.getItem('userAcceptedLogged');
                  let twoFactorAuthType = localStorage.getItem('twoFactorAuthType');  
                  let headers = {
                    'Content-Type': 'application/json',
                  };                  
                  //alert(email);
                  Api.post("/users/login/email",
                    { email, useracceptedlogged: userAcceptedLogged, ismobile: false, twoFactorAuthCode, twoFactorAuthType 
                      , id_token_gov: result.data.id_token, access_token_gov: result.data.access_token
                    },
                    { headers: headers }
                  )
                    .then(async result => {
              
                      //console.log("entrei aqui result login 1.1")
                      //console.log(result)
              
                      //console.log("entrei no login aqui ")
                      if (result.data.success) {
                        //console.log("entrei no result.data.success")
                        //console.log(result.data.data)
                        resetLocalStorageVariables()
                        this.props.clearlastCalledsQueue()
                        localStorage.setItem('userid', result.data.data.id);
                        if (result.data.data.firstaccess == false && result.data.data.system == true) {
                          if (result.data.data.userrole && result.data.data.userrole.length > 0) {
                            this.props.addUserSession(result.data.data);
                            this.props.history.push({
                              pathname: `/pt/adm-password-reset`,
                              state: {
                                user: result.data.data
                              }
                            });
                          }
                        } else {
              
                          localStorage.setItem("refreshTokenTime", "0");
                          this.props.addUserSession(result.data.data);
              
                          localStorage.setItem('accesstoken', result.data.data.accesstoken);
                          localStorage.setItem('accesstokenreports', result.data.data.accesstokenreports);
                          localStorage.setItem('accesstoken2talk', result.data.data.accesstoken2talk);
                          localStorage.setItem('reload', true);
              
              
                          const rolesUser = result.data?.userrole ?? [];
              
                          rolesUser.forEach(obj => {
                            if (obj.role && obj.role.id === 4) {
                              localStorage.setItem('@user-client-session-2do-logged', JSON.stringify({
                                name: result.data.data?.name,
                                email: result.data.data?.email
                              }));
                              return;
                            }
                          });
              
                          console.log(this.props)
                          var l = "pt";
                          if(this.props.match && this.props.match.params && this.props.match.params.lang){
                            l = this.props.match.params.lang;
                          }
                          //alert(l);
                          //alert(result.data.data.orgid);
                          if (result.data.data.orgid != 0 && result.data.data.orgid != null) {
                          } else {
                            if (result.data.data.startpage && result.data.data.startpage.length > 0 && result.data.data.startpage != 'undefined' && result.data.data.startpage != 'null') {

                              //alert(`IF: /${l}/${result.data.data.startpage}`);
                              document.location.href = `/${l}/${result.data.data.startpage}`
                            } else {
                              //alert(`ELSE: /${l}/calleds`);
                              document.location.href = `/${l}/calleds`
                            }
                          }
              
                        }
                        //document.location.href="/calleds";
                      }
                      else{
                        this.setState({
                          loading: false,
                          openNotification: true,
                          notificationVariant: "error",
                          notificationMessage: "Não foi possível validar o acesso -> "+JSON.stringify(result.data.errors)
                        });

                      }
                    })
                    .catch(err => {
                      const intl = this.props.intl;
                      console.log("entrei aqui result login 1.2")
                      console.log(err)
                      if (err.response && err.response.data && err.response.data.errors) {
                        if (err.response.data.errors && err.response.data.errors[0] == "Error: ChangePassword") {
              
                          this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: this.props.intl.formatMessage({ id: "passwordpolicy.passwordexpirationtimewarning" })
                          });
                        } else if (err.response.data.errors && err.response.data.errors[0] == "Error: TermAccepted") {
                          this.setState({
                            loading: false,
                            openDialogTerm: true
                          });
                        } else if (err.response.data.errors && err.response.data.errors[0] == "Error: UserLogged") {
                          this.setState({
                            loading: false,
                            openDialogUserLogged: true
                          });
                        } else if (err.response.data.errors && err.response.data.errors[0] == "Error: TwoFactorAuthenticationEnableEmail") {
                          this.setState(prevState => ({
                            signInForm: {
                              ...prevState.signInForm,
                              twoFactorAuthCode : ""
                            },
                            loading: false,
                            openDialogTwoFactorAuth: true,
                            twoFactorAuthType: "email"
                          }));
                         
                        } else if (err.response.data.errors && err.response.data.errors[0] == "Error: TwoFactorAuthenticationEnable") {
                          this.setState({
                            loading: false,
                            openDialogTwoFactorAuth: true,
                            twoFactorAuthType: "app"
                          });
                        } 
              
                     
                        else if (err.response.data.errors && err.response.data.errors[0] == "Error: ConfigTwoFactorAuthenticationAppEnable") {
                          PapiroConsole.log("entrei aqui result login 2")
                          PapiroConsole.log(err.response.data)
                          PapiroConsole.log(err.response.data)
                          let { email } = this.state.signInForm;
                          localStorage.setItem('accesstoken', err.response.data.accesstoken);
                          localStorage.setItem('emailtoactivate', email);
                          this.goToEnableTwoFactorAuthenticationApp()
                        }
                        
                        else {
              
                          this.setState(prevState => ({
                            signInForm: {
                              ...prevState.signInForm,
                              twoFactorAuthCode : ""
                            },
                            loading: false,
                            openNotification: true,
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                          }));
                        }
              
                      }
                      else {
              
                        this.setState(prevState => ({
                          signInForm: {
                            ...prevState.signInForm,
                            twoFactorAuthCode : ""
                          },
                          loading: false,
                          notificationVariant: "error",
                          openNotification: true,
                          notificationMessage: this.props.intl.formatMessage({ id: "process.error" })
                        }));
                       
              
                      }
                    });                  


            })
              .catch(err => {
                  this.setState({
                      loading: false,
                      openNotification: true,
                      notificationVariant: "error",
                      notificationMessage: err.response.data.error+" -> "+err.response.data.error_description
                  });
                  window.history.back(-1);
              });
          }
          else{
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage: "Acesso não permitido."
              });

          }

        }

    } catch (err) {
      console.error(err);

    } finally {
      this.setState({ loading: false });
    }
  }
  closeNotification() {
    this.setState({ openNotification: false });
  }

  async componentDidMount() {
    await this.auth();
  }

  render() {

    return (
      <div>
         <main>
          <Container component='main' maxWidth='md'>
            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            {
              this.state.openNotification && (
                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
              )
            }          
          </Container>
         </main>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userSession: state.userSession,
  match: state.match
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(Object.assign({}, userSessionActions, lastCalledsQueueActions), dispatch)

export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(Authorize)));

Authorize.contextType = AbilityContext;