import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import CalledAccompaniment from './pages/called-accompaniment';
import CalledClassificationReports from './pages/called-classification-report';
import Calleds from './pages/called-list';
import CalledRegistration from "./pages/called-registration";
import CalledRegistrationClient from "./pages/called-registration-client";
import CalledReports from './pages/called-report-list';
import CalledStatuses from './pages/calledstatus-list';
import CategoryEdit from "./pages/category-edit";
import Categories from './pages/category-list';
import CategoryRegistration from "./pages/category-registration";
import Dashboard from "./pages/dashboard";
import ForgotPassword from "./pages/forgot-password";
import GlobalAdmPasswordReset from "./pages/global-adm-password-reset";
import Login from "./pages/login";
import LoginNew from "./pages/login/index-new";
import MaterialReports from './pages/material-report-list';
import PasswordReset from "./pages/password-reset";
import PasswordResetAuth from "./pages/password-reset-auth";
import ProductivityDashboard from './pages/productivity-dashboard';
import QualityDashboard from './pages/quality-dashboard';
import SlaEdit from './pages/sla-edit';
import Slas from './pages/sla-list';
import SlaRegistration from './pages/sla-registration';
import TabCalledReportInformation from './pages/tab-called-report';

import CalledRejectionEdit from './pages/called-rejection-edit';
import CalledRejection from './pages/called-rejection-list';
import AuditReport from './pages/report-audit-list';

import UserTechinicalTeamReport from './pages/techinicalteamusers-report';


import TechinicalteamReport from './pages/techinicalteam-report';



import NpsSearch from './pages/nps-search';

import OrganizationAccountEmailEdit from './pages/organizationaccountemail-edit';
import OrganizationAccountEmails from './pages/organizationaccountemail-list';
import OrganizationAccountEmailRegistration from './pages/organizationaccountemail-registration';

import TypeEdit from './pages/type-edit';
import Types from './pages/type-list';
import TypeRegistration from './pages/type-registration';

import OrganizationEdit from './pages/organization-edit';
import Organizations from './pages/organization-list';
import OrganizationRegistration from './pages/organization-registration';

import SegmentEdit from './pages/segment-edit';
import Segments from './pages/segment-list';
import SegmentRegistration from './pages/segment-registration';

import ActivitiesList from './pages/activity-list';
import ReasonList from './pages/reason-list';
import ReasonRegistration from './pages/reason-registration';


import ActivitiesRegistration from './pages/activity-registration';
import NpsSearchEdit from './pages/nps-search-edit';
import NpsSearchRegistration from './pages/nps-search-registration';


import ActivitiesEdit from './pages/activity-edit';



import ReasonEdit from './pages/reason-edit';

import GroupEdit from './pages/group-edit';
import Groups from './pages/group-list';
import GroupRegistration from './pages/group-registration';

import AddressEdit from './pages/address-edit';
import Addresses from './pages/address-list';
import AddressRegistration from './pages/address-registration';

import Security from './pages/security-list';
import UserEdit from './pages/user-edit';
import UserEditClient from './pages/user-edit-client';
import UserImport from './pages/user-import';
import Users from './pages/user-list';
import UserRegistration from './pages/user-registration';

import UserClientEdit from './pages/user-client-edit';
import UsersClient from './pages/user-client-list';
import UserClientRegistration from './pages/user-client-registration';

import OrganizationSettingEdit from './pages/organizationsetting-edit';
import OrganizationSettings from './pages/organizationsetting-list';
import OrganizationSettingRegistration from './pages/organizationsetting-registration';

import OriginRequestEdit from './pages/originrequest-edit';
import OriginRequests from './pages/originrequest-list';
import OriginRequestRegistration from './pages/originrequest-registration';

import CalledClassificationEdit from './pages/calledclassification-edit';
import CalledClassifications from './pages/calledclassification-list';
import CalledClassificationRegistration from './pages/calledclassification-registration';

import Feedback from './pages/feedback';
import NpsEmailRating from './pages/nps-email-rating';

import TechinicalTeamEdit from './pages/techinicalteam-edit';
import TechinicalTeams from './pages/techinicalteam-list';
import TechinicalTeamRegistration from './pages/techinicalteam-registration';

import TechinicalTeamUserEdit from './pages/techinicalteamuser-edit';
import TechinicalTeamUsers from './pages/techinicalteamuser-list';
import TechinicalTeamUserRegistration from './pages/techinicalteamuser-registration';

import TechinicalTeamOrganizationCategoryEdit from './pages/techinicalteamorganizationcategory-edit';
import TechinicalTeamOrganizationCategories from './pages/techinicalteamorganizationcategory-list';
import TechinicalTeamOrganizationCategoryRegistration from './pages/techinicalteamorganizationcategory-registration';

import CategoryOrganizationEdit from './pages/categoryorganization-edit';
import CategoryOrganizations from './pages/categoryorganization-list';
import CategoryOrganizationRegistration from './pages/categoryorganization-registration';
import PermissionEdit from './pages/permission-edit';
import Permissions from './pages/permission-list';
import PermissionRegistration from './pages/permission-registration';
import RoleEdit from './pages/role-edit';
import Roles from './pages/role-list';
import RoleRegistration from './pages/role-registration';
import TechinicalTeamOrganizationEdit from './pages/techinicalteamorganization-edit';
import TechinicalTeamOrganizations from './pages/techinicalteamorganization-list';
import TechinicalTeamOrganizationRegistration from './pages/techinicalteamorganization-registration';

import accountConfirmation from './pages/account-confirmation';
import CalledReportInformation from './pages/called-report-information';
import ExternalCalledReport from './pages/external-called-report';
import OrganizationCalendarEdit from './pages/organizationcalendar-edit';
import OrganizationCalendars from './pages/organizationcalendar-list';
import OrganizationCalendarRegistration from './pages/organizationcalendar-registration';

import OrganizationFieldEdit from './pages/organizationfield-edit';
import OrganizationFields from './pages/organizationfield-list';
import OrganizationFieldRegistration from './pages/organizationfield-registration';

import OrganizationChecklistEdit from './pages/organizationchecklist-edit';
import OrganizationChecklists from './pages/organizationchecklist-list';
import OrganizationChecklistRegistration from './pages/organizationchecklist-registration';

import CalledListDatas from './pages/calledlistdata-list';

import AssignedCalledEdit from './pages/assignedcalled-edit';
import AssignedCalledRegistration from './pages/assignedcalled-registration';

import StatusEdit from './pages/status-edit';
import Statuses from './pages/status-list';
import StatusRegistration from './pages/status-registration';

import ConfigEdit from './pages/config-edit';
import Configs from './pages/config-list';
import ConfigRegistration from './pages/config-registration';

import CalledReportConfigEdit from './pages/called-report-config-edit';
import CalledReportConfigs from './pages/called-report-config-list';
import CalledReportConfigRegistration from './pages/called-report-config-registration';

import feedbackAssigned from './pages/feedback-assigned';

import CalledFlowEdit from './pages/calledflow-edit';
import CalledFlows from './pages/calledflow-list';
import CalledFlowRegistration from './pages/calledflow-registration';

import CalledFlowStatusEdit from './pages/calledflowstatus-edit';
import CalledFlowStatuses from './pages/calledflowstatus-list';
import CalledFlowStatusRegistration from './pages/calledflowstatus-registration';

import DistributionStrategyEdit from './pages/distributionstrategy-edit';
import DistributionStrategies from './pages/distributionstrategy-list';
import DistributionStrategyRegistration from './pages/distributionstrategy-registration';

import OrganizationCategoryEdit from './pages/organizationcategory-edit';
import OrganizationCategories from './pages/organizationcategory-list';
import OrganizationCategoryRegistration from './pages/organizationcategory-registration';

import OrganizationSlaEdit from './pages/organizationsla-edit';
import OrganizationSlas from './pages/organizationsla-list';
import OrganizationSlaRegistration from './pages/organizationsla-registration';


import ScheduleWorkHourEdit from './pages/scheduleworkhour-edit';
import ScheduleWorkHours from './pages/scheduleworkhour-list';
import ScheduleWorkHourRegistration from './pages/scheduleworkhour-registration';

import SlaCountTypeEdit from './pages/slacounttype-edit';
import SlaCountTypes from './pages/slacounttype-list';
import SlaCountTypeRegistration from './pages/slacounttype-registration';

import { LANGUAGES } from './_i18n/languages';
import Page404 from './pages/page404/page404';
import WorkHourEdit from './pages/workhour-edit';
import WorkHourEditRegister from './pages/workhour-edit-register';
import WorkHours from './pages/workhour-list';
import WorkHourRegistration from './pages/workhour-registration';

import HolidayEdit from './pages/holiday-edit';
import Holidays from './pages/holiday-list';
import HolidayRegistration from './pages/holiday-registration';


import MaterialEdit from './pages/material-edit';
import Materials from './pages/material-list';
import MaterialRegistration from './pages/material-registration';

import OrganizationDepartamentEdit from './pages/organizationdepartament-edit';
import OrganizationDepartaments from './pages/organizationdepartament-list';
import OrganizationDepartamentRegistration from './pages/organizationdepartament-registration';

import OrganizationMaterialEdit from './pages/organizationmaterial-edit';
import OrganizationMaterials from './pages/organizationmaterial-list';
import OrganizationMaterialRegistration from './pages/organizationmaterial-registration';

import OrganizationPatrimonyEdit from './pages/organizationpatrimony-edit';
import OrganizationPatrimonies from './pages/organizationpatrimony-list';
import OrganizationPatrimonyRegistration from './pages/organizationpatrimony-registration';
import RelationshipCalledRegistration from './pages/relationshipcalled-registration';


import PatrimonyGroupEdit from './pages/patrimonygroup-edit';
import PatrimonyGroups from './pages/patrimonygroup-list';
import PatrimonyGroupRegistration from './pages/patrimonygroup-registration';

import PatrimonyMaterialEdit from './pages/patrimonymaterial-edit';
import PatrimonyMaterials from './pages/patrimonymaterial-list';
import PatrimonyMaterialRegistration from './pages/patrimonymaterial-registration';

import PatrimonySubGroupEdit from './pages/patrimonysubgroup-edit';
import PatrimonySubGroups from './pages/patrimonysubgroup-list';
import PatrimonySubGroupRegistration from './pages/patrimonysubgroup-registration';

import KnowledgeBaseEdit from './pages/knowledgebase-edit';
import KnowledgeBases from './pages/knowledgebase-list';
import ClientKnowledgeBases from './pages/knowledgebase-list-client';
import ExternalKnowledgeBases from './pages/knowledgebase-list-external';
import KnowledgeBaseRegistration from './pages/knowledgebase-registration';
import Portal from './pages/portal';

import SendMailReport from './pages/send-mail-report';
import SendSMSReport from './pages/send-sms-report';

import DashboardReport from './pages/dashboard-report';

import ChecklistReports from './pages/checklist-report';
import CostReport from './pages/cost-report';

import MyPlan from './pages/my-plan';
import Unavailable from './pages/unavailable';

import Steps from './pages/steps';

import Webook from './pages/webhook';
import Wizard from './pages/wizard/wizard';

import AdditionalCalledCostEdit from './pages/additional-called-cost-edit';
import AdditionalCalledCostRegistration from './pages/additional-called-cost-registration';
import AccountConfirmationAdmHirer from './pages/admin/account-confirmation';
import ForgotPasswordAdmin from './pages/admin/forgot-password/index';
import LoginAdmHirer from './pages/admin/login';
import MultiHirer from './pages/admin/multi-hirer';
import MultiHirerEdit from './pages/admin/multi-hirer/edit';
import MultiHirerRegister from './pages/admin/multi-hirer/register';
import CalledTimeEntriesEdit from './pages/called-time-entries-edit';
import CalledTimeEntriesRegistration from './pages/called-time-entries-registration';
import chatReport from './pages/chat-report';
import ContractInvalid from './pages/contract-invalid/index';
import FcrReport from './pages/fcr-report/index';
import UserErrorReport from './pages/user-error-report/index';

import DefaultActionsEdit from './pages/default-actions-edit';
import DefaultActions from './pages/default-actions-list';
import DefaultActionsRegistration from './pages/default-actions-registration';
import SecurityRegistration from './pages/security-registration';

import PriorityEdit from './pages/priority-edit';
import Priorities from './pages/priority-list';
import PriorityRegistration from './pages/priority-registration';

import AdditionalCalledCostItems from './pages/additional-service-config-list';
import AdditionalCalledCostItemEdit from './pages/additional-service-config-list/components/additional-called-cost-item/edit';
import AdditionalCalledCostItemRegistration from './pages/additional-service-config-list/components/additional-called-cost-item/registration';
import AdditionalCalledCostItemReasonEdit from './pages/additional-service-config-list/components/additional-called-cost-reason/edit';
import AdditionalCalledCostItemReasonRegistration from './pages/additional-service-config-list/components/additional-called-cost-reason/registration';
import AdditionalCalledCostItemTypeEdit from './pages/additional-service-config-list/components/additional-called-cost-type/edit';
import AdditionalCalledCostItemTypeRegistration from './pages/additional-service-config-list/components/additional-called-cost-type/registration';
import CalledClosingStatusEdit from './pages/called-closing-status-edit';
import CalledClosingStatuses from './pages/called-closing-status-list';
import CalledClosingStatusRegistration from './pages/called-closing-status-register';
import CalledServiceSettingsList from './pages/called-service-settings';
import calledServiceSettings from './pages/called-service-settings-edit';
import calledlistdataEdit from './pages/calledlistdata-edit';
import calledlistdataRegistration from './pages/calledlistdata-registration';
import CalledsOrganizationsSyntheticReport from './pages/calleds-organizations-synthetic-report';
import CalledsOrganizationsReport from './pages/calledsorganizations-report';
import Cardinformations from './pages/cardinformations';
import ExternalUserRegistration from './pages/external-user-registration';
import OrganizationFieldReportBlocks from './pages/organizationfieldreportblock-list';
import ReopenedCalledsReport from './pages/reopened-calleds-report';
import UnitEdit from './pages/unit-edit';
import unitList from './pages/unit-list';
import UnitRegistration from './pages/unit-registration';


import AgentsReport from './pages/agents-report';
import Attendance from './pages/attendance';
import CalledEditOrganization from './pages/called-edit-organization';
import CalledView from './pages/called-view';
import CalledsOriginCategoriesReport from './pages/calleds-origin-categories-report';
import EnableAgents from './pages/enable-agents';
import PhoneRegistrationRequests from './pages/phone-registration-requests';
import PhoneRegistrationRequestsEdit from './pages/phone-registration-requests-edit';
import PhoneRegistrationRequestsRegistration from './pages/phone-registration-requests-registration';
import RulesAttendanceEdit from './pages/rules-attendance-edit';
import RulesAttendance from './pages/rules-attendance-list';
import RulesAttendanceRegistration from './pages/rules-attendance-registration';
import UsersReport from './pages/users-report';
import { PapiroConsole } from './utils/papiroConsole';

import Omnichannel from './pages/2talk-chat';
import ToTalkChatAttendance from './pages/2talk-chat-attendance-list';
import OmnichannelObserve from './pages/2talk-chat-observe';
import AuthenticationEdit from './pages/authentication-edit';
import AuthenticationList from './pages/authentication-list';
import IntegrationServiceList from './pages/integration-service-list';
import AuthenticationRegistration from './pages/authentication-registration';
import channel from './pages/channel';
import channelEdit from './pages/channel-edit';
import channelRegistration from './pages/channel-registration';
import chatClassifications from './pages/chat-classifications';
import chatClassificationsEdit from './pages/chat-classifications-edit';
import chatbotEdit from './pages/chatbot-edit';
import chatbotList from './pages/chatbot-list';
import TwoTalkChatReport from './pages/2talk-chat-report';
import chatbotRegistration from './pages/chatbot-registration';
import companyEdit from './pages/company-edit';
import companyList from './pages/company-list';
import companyRegistration from './pages/company-registration';
import customerEdit from './pages/customer-edit';
import customerList from './pages/customer-list';
import customerRegistration from './pages/customer-registration';
import HoursReported from './pages/hours-reported';
import OrganizationsCategoriesReport from './pages/organizations-categories-report';
import paramsEdit from './pages/params-edit';
import ParamsList from './pages/params-list';
import paramsRegistration from './pages/params-registration';
import QuantitativeCalledsReport from './pages/quantitative-calleds-report';
import SatisfactionSurveyEdit from './pages/satisfaction-survey-edit';
import SatisfactionSurveyList from './pages/satisfaction-survey-list';
import SatisfactionSurveyRegistration from './pages/satisfaction-survey-registration';
import SecurityEdit from './pages/security-edit';
import SecurityItemRegistered from './pages/security-item-registered';
import webchatEdit from './pages/webchat-edit';
import webchatList from './pages/webchat-list';
import webchatRegistration from './pages/webchat-registration';
import IntegrationUserVariableList from './pages/integration-user-variable-list';
import IntegrationUserVariableRegistration from './pages/integration-user-variable-registration';
import IntegrationUserVariableEdit from './pages/integration-user-variable-edit';
import IntegrationServiceRegistration from './pages/integration-service-registration';
import IntegrationServiceEdit from './pages/integration-service-edit';
import quickresponseList from './pages/quickresponse-list';
import quickresponseRegistration from './pages/quickresponse-registration';
import quickresponseEdit from './pages/quickresponse-edit';
import Authorize from './pages/authorize/index';
import AuthorizeExample from './pages/authorize/example';
import TwoFactorAuthenticationApp from './pages/two-factor-authentication-app';

const MultiLanguageRoute = (props) => {
  const defaultLanguage = LANGUAGES.pt.urlLang;
  const hasLang = props.computedMatch.params.lang;
  const url = props.computedMatch.url;
  const is404Page = props.path;
  const isBasePathWithoutLang = props.path === '/';
  
  var currentUrlLang = window.location.pathname.split('/')[1];
  var currentLanguage = LANGUAGES[currentUrlLang];
  if(!currentLanguage) {
    currentLanguage = LANGUAGES[LANGUAGES.default];
  }
  if(props.path === '/:lang/calleds' || props.path === '/:lang/finishedCalleds/:q?' || props.path === '/:lang/assignedcalleds' || props.path === '/:lang/archived-calleds/:q?' || props.path === '/:lang/canceled-calleds/:q?' ) {
    let pathnameSplit = props.path.split('/')
    PapiroConsole.log("=== routes ===")
    PapiroConsole.log(pathnameSplit)
    if (pathnameSplit && pathnameSplit.length > 0) {
      PapiroConsole.log(pathnameSplit)
      let routePage = props.path === '/:lang/calleds' || props.path === '/:lang/assignedcalleds' ? pathnameSplit[pathnameSplit.length - 1] : pathnameSplit[pathnameSplit.length - 2]
      if (routePage != null) {
        PapiroConsole.log(routePage)
        localStorage.removeItem('calledListPage')
        localStorage.setItem('calledListPage', `${routePage}`)
      }
    }
  }
  if(props.path !== '/:lang/calleds' && props.path !== '/:lang/finishedCalleds/:q?' && props.path !== '/:lang/assignedcalleds' && props.path !== '/:lang/archived-calleds/:q?' && props.path !== '/:lang/called-accompaniment/:id' || props.path === '/:lang/canceled-calleds/:q?') {
    localStorage.setItem('newFilter', '')
    localStorage.setItem('newSearch', '')
    localStorage.setItem('newOrderFilter', '');
    localStorage.setItem('newOrderSearch', '');
    localStorage.setItem('calledsPage', '1')
  }
  if(props.path !== '/:lang/organizations' && props.path !== '/:lang/organization-edit') {
    localStorage.setItem('organizationsTableFilter', '')
    localStorage.setItem('organizationsTablePage', '1')
  }
  if(props.path !== '/:lang/organizationslas' && props.path !== '/:lang/organizationsla-edit' && props.path !== '/:lang/organization-edit') {
    localStorage.setItem('organizationSLATableFilter', '')
    localStorage.setItem('organizationSLATableOrganizationFilter', '')
    localStorage.setItem('organizationSLATablePage', '1')
  }
  if(props.path !== '/:lang/organizationcategories' && props.path !== '/:lang/organizationcategory-edit' && props.path !== '/:lang/organization-edit') {
    localStorage.setItem('organizationCategoriesTableFilter', '')
    localStorage.setItem('organizationCategoryTableOrganizationFilter', '')
    localStorage.setItem('organizationCategoriesTablePage', '1')
  }
  if(props.path !== '/:lang/organizationdepartaments' && props.path !== '/:lang/organizationdepartament-edit' && props.path !== '/:lang/organization-edit') {
    localStorage.setItem('organizationDepartamentsTableFilter', '')
    localStorage.setItem('organizationDetartamentsTableOrganizationFilter', '')
    localStorage.setItem('organizationDepartamentsTablePage', '1')
  }
  if(props.path !== '/:lang/organizationpatrimonies' && props.path !== '/:lang/organizationpatrimony-edit' && props.path !== '/:lang/organization-edit') {
    localStorage.setItem('organizationPatrimoniesTableFilter', '')
    localStorage.setItem('organizationPatrimoniesTableOrganizationFilter', '')
    localStorage.setItem('organizationPatrimoniesTablePage', '1')
  }
  if(props.path !== '/:lang/organizationmaterials' && props.path !== '/:lang/organizationmaterial-edit' && props.path !== '/:lang/organization-edit') {
    localStorage.setItem('organizationMaterialsTableFilter', '')
    localStorage.setItem('organizationMaterialsTableOrganizationFilter', '')
    localStorage.setItem('organizationMaterialsTablePage', '1')
  }
  if(props.path !== '/:lang/addresses' && props.path !== '/:lang/address-edit' && props.path !== '/:lang/organization-edit') {
    localStorage.setItem('organizationAddressesTableFilter', '')
    localStorage.setItem('organizationAddressesTableOrganizationFilter', '')
    localStorage.setItem('organizationAddressesTablePage', '1')
  }
  if(props.path !== '/:lang/organizationcalendars' && props.path !== '/:lang/organizationcalendar-edit' && props.path !== '/:lang/organization-edit') {
    localStorage.setItem('organizationCalendarTableFilter', '')
    localStorage.setItem('organizationCalendarTableOrganizationFilter', '')
    localStorage.setItem('organizationCalendarTablePage', '1')
  }
  if(props.path !== '/:lang/organizationfields' && props.path !== '/:lang/organizationfield-edit' && props.path !== '/:lang/organization-edit') {
    localStorage.setItem('organizationFieldsTableFilter', '')
    localStorage.setItem('organizationFieldsTableOrganizationFilter', '')
    localStorage.setItem('organizationFieldsTablePage', '1')
  }
  if(props.path !== '/:lang/organizationchecklists' && props.path !== '/:lang/organizationchecklist-edit' && props.path !== '/:lang/organization-edit') {
    localStorage.setItem('organizationChecklistsTableFilter', '')
    localStorage.setItem('organizationChecklistsTableOrganizationFilter', '')
    localStorage.setItem('organizationChecklistsTablePage', '1')
  }

  if(isBasePathWithoutLang)  return  <Redirect to={`/${defaultLanguage}/login`} />
  if(!hasLang && !is404Page)

  var path = `${url}`;
  if(hasLang == url || '/'+hasLang == url){
     path = `/${currentLanguage.urlLang}${url}`;
  }
  return <Route language={currentLanguage.urlLang} {...props} />    
}

export default function Routes() {
  return (
    <BrowserRouter forceRefresh={false}>
      <Switch>
      <MultiLanguageRoute path="/" exact component={Login} />
      <MultiLanguageRoute path="/:lang/" exact component={Login} />
      <MultiLanguageRoute path="/:lang/contractinvalid" exact component={ContractInvalid} />
      <MultiLanguageRoute path="/:lang/two-factor-authentication-app" exact component={TwoFactorAuthenticationApp} />
      <MultiLanguageRoute path="/:lang/login" exact component={Login} />
      <MultiLanguageRoute path="/:lang/new-login" exact component={LoginNew} />
        <MultiLanguageRoute path="/:lang/forgot-password" component={ForgotPassword} />
        <MultiLanguageRoute path="/:lang/user-registration" component={UserRegistration} />
        <MultiLanguageRoute path="/:lang/user-import" component={UserImport} />
        <MultiLanguageRoute path="/:lang/dashboard" component={Dashboard} />
        <MultiLanguageRoute path="/:lang/qualitydashboard" component={QualityDashboard} />
        <MultiLanguageRoute path="/:lang/productivitydashboard" component={ProductivityDashboard} />
        <MultiLanguageRoute path="/:lang/called-registration" component={CalledRegistration} />
        <MultiLanguageRoute path="/:lang/called-registration-client" component={CalledRegistrationClient} />
        <MultiLanguageRoute path="/:lang/password-reset/:token?" component={PasswordReset} />
        <MultiLanguageRoute path="/:lang/password-reset-auth" component={PasswordResetAuth} />
        <MultiLanguageRoute path="/:lang/adm-password-reset" component={GlobalAdmPasswordReset} />
        <MultiLanguageRoute path="/:lang/calleds" component={Calleds} />
        <MultiLanguageRoute path="/:lang/calledreports" component={CalledReports} />
        <MultiLanguageRoute path="/:lang/sendmailreports" component={SendMailReport} />
        <MultiLanguageRoute path="/:lang/sendsmsreports" component={SendSMSReport} />
        <MultiLanguageRoute path="/:lang/chatreports" component={chatReport} />
        <MultiLanguageRoute path="/:lang/dashboardreports" component={DashboardReport} />

        <MultiLanguageRoute path="/:lang/costreports" component={CostReport} />

        <MultiLanguageRoute path="/:lang/checklistreports" component={ChecklistReports} />

        <MultiLanguageRoute path="/:lang/called-classification-reports" component={CalledClassificationReports} />
        <MultiLanguageRoute path="/:lang/materialReports" component={MaterialReports} />
        <MultiLanguageRoute path='/:lang/calleds/:q?' component={Calleds} />
        
        <MultiLanguageRoute path='/:lang/finishedCalleds/:q?' component={Calleds} />

        <MultiLanguageRoute path='/:lang/organizationaccountemails' component={OrganizationAccountEmails} />
        <MultiLanguageRoute path='/:lang/organizationaccountemail-registration' component={OrganizationAccountEmailRegistration} />
        <MultiLanguageRoute path='/:lang/organizationaccountemail-edit' component={OrganizationAccountEmailEdit} />

        {/*<MultiLanguageRoute path="/:lang/called-accompaniment" component={CalledAccompaniment} />*/}
        <MultiLanguageRoute path="/:lang/called-accompaniment/:id" component={CalledAccompaniment} />
        <MultiLanguageRoute path="/:lang/called-view/:id" component={CalledView} />
        <MultiLanguageRoute path="/:lang/called-edit-organization/:id" component={CalledEditOrganization} />
        
        <MultiLanguageRoute path="/:lang/users" component={Users} />
        <MultiLanguageRoute path="/:lang/categories" component={Categories} />
        <MultiLanguageRoute path="/:lang/category-registration" component={CategoryRegistration} />
        <MultiLanguageRoute path="/:lang/category-edit" component={CategoryEdit} />
        <MultiLanguageRoute path='/:lang/slas' component={Slas} />
        <MultiLanguageRoute path='/:lang/sla-registration' component={SlaRegistration} />
        <MultiLanguageRoute path='/:lang/sla-edit' component={SlaEdit} />
        <MultiLanguageRoute path='/:lang/types' component={Types} />
        <MultiLanguageRoute path='/:lang/type-registration' component={TypeRegistration} />
        <MultiLanguageRoute path='/:lang/type-edit' component={TypeEdit} />

        <MultiLanguageRoute path='/:lang/organizations' component={Organizations} />
        <MultiLanguageRoute path='/:lang/organization-registration' component={OrganizationRegistration} />
        <MultiLanguageRoute path='/:lang/organization-edit' component={OrganizationEdit} />
        <MultiLanguageRoute path='/:lang/wizard-organization' component={Wizard} />

        <MultiLanguageRoute path='/:lang/segments' component={Segments} />
        <MultiLanguageRoute path='/:lang/segment-registration' component={SegmentRegistration} />
        
        <MultiLanguageRoute path='/:lang/segment-edit' component={SegmentEdit} />

        <MultiLanguageRoute path='/:lang/satisfaction-survey-list' component={SatisfactionSurveyList} />
        <MultiLanguageRoute path='/:lang/satisfaction-survey-registration' component={SatisfactionSurveyRegistration} />
        <MultiLanguageRoute path='/:lang/satisfaction-survey-edit' component={SatisfactionSurveyEdit} />
        
        <MultiLanguageRoute path='/:lang/segment-edit' component={SegmentEdit} />

        <MultiLanguageRoute path='/:lang/reason-registration' component={ReasonRegistration} />
        <MultiLanguageRoute path='/:lang/reasons' component={ReasonList} />
        <MultiLanguageRoute path='/:lang/activity-list' component={ActivitiesList} />
        <MultiLanguageRoute path='/:lang/activity-registration' component={ActivitiesRegistration} />
        <MultiLanguageRoute path='/:lang/nps-search-registration' component={NpsSearchRegistration} />

        <MultiLanguageRoute path='/:lang/nps-search-edit' component={NpsSearchEdit} />
        
        <MultiLanguageRoute path='/:lang/activity-edit' component={ActivitiesEdit} />

        <MultiLanguageRoute path='/:lang/called-time-entries-edit' component={CalledTimeEntriesEdit} />

        <MultiLanguageRoute path='/:lang/audit-report' component={AuditReport} />

        <MultiLanguageRoute path='/:lang/techinicalteamusers-report' component={UserTechinicalTeamReport}/>

        <MultiLanguageRoute path='/:lang/techinicalteam-report' component={TechinicalteamReport}/>

        <MultiLanguageRoute path='/:lang/calleds-organizations-report' component={CalledsOrganizationsReport}/>

        <MultiLanguageRoute path='/:lang/calleds-origin-categories-report' component={CalledsOriginCategoriesReport}/>

        <MultiLanguageRoute path='/:lang/calleds-organizations-synthetic-report' component={CalledsOrganizationsSyntheticReport}/>

        <MultiLanguageRoute path='/:lang/reopened-calleds-report' component={ReopenedCalledsReport}/>
        
        <MultiLanguageRoute path='/:lang/agents-report' component={AgentsReport}/>

        <MultiLanguageRoute path='/:lang/organizations-categories-report' component={OrganizationsCategoriesReport}/>


        <MultiLanguageRoute path='/:lang/quantitative-calleds-report' component={QuantitativeCalledsReport}/>

        <MultiLanguageRoute path='/:lang/users-report' component={UsersReport}/>


        <MultiLanguageRoute path='/:lang/hours-report' component={HoursReported}/>
        
        
        
        
        <MultiLanguageRoute path='/:lang/reason-edit' component={ReasonEdit} />

        <MultiLanguageRoute path='/:lang/groups' component={Groups} />
        <MultiLanguageRoute path='/:lang/group-registration' component={GroupRegistration} />
        <MultiLanguageRoute path='/:lang/group-edit' component={GroupEdit} />

        <MultiLanguageRoute path='/:lang/addresses' component={Addresses} />
        <MultiLanguageRoute path='/:lang/address-registration' component={AddressRegistration} />
        <MultiLanguageRoute path='/:lang/address-edit' component={AddressEdit} />

        <MultiLanguageRoute path='/:lang/organizationsettings' component={OrganizationSettings} />
        <MultiLanguageRoute path='/:lang/organizationsettings-registration' component={OrganizationSettingRegistration} />
        <MultiLanguageRoute path='/:lang/organizationsettings-edit' component={OrganizationSettingEdit} />

        <MultiLanguageRoute path='/:lang/originrequests' component={OriginRequests} />
        <MultiLanguageRoute path='/:lang/originrequest-registration' component={OriginRequestRegistration} />
        <MultiLanguageRoute path='/:lang/originrequest-edit' component={OriginRequestEdit} />

        <MultiLanguageRoute path='/:lang/calledclassifications' component={CalledClassifications} />

        <MultiLanguageRoute path='/:lang/nps-search' component={NpsSearch} />

        
        <MultiLanguageRoute path='/:lang/calledclassification-registration' component={CalledClassificationRegistration} />
        <MultiLanguageRoute path='/:lang/calledclassification-edit' component={CalledClassificationEdit} />

        <MultiLanguageRoute path='/:lang/users' component={Users} />
        <MultiLanguageRoute path='/:lang/user-registration' component={UserRegistration} />
        <MultiLanguageRoute path='/:lang/user-import' component={UserImport} />
        <MultiLanguageRoute path='/:lang/user-edit' component={UserEdit} />
        <MultiLanguageRoute path='/:lang/user-edit-client' component={UserEditClient} />

        <MultiLanguageRoute path='/:lang/security' component={Security} />
        <MultiLanguageRoute path='/:lang/security-registration' component={SecurityRegistration} />
        <MultiLanguageRoute path='/:lang/security-edit' component={SecurityEdit} />

        <MultiLanguageRoute path='/:lang/security-item-registered' component={SecurityItemRegistered} />

        <MultiLanguageRoute path='/:lang/users-client' component={UsersClient} />
        <MultiLanguageRoute path='/:lang/user-client-registration' component={UserClientRegistration} />
        <MultiLanguageRoute path='/:lang/user-client-edit' component={UserClientEdit} />

        <MultiLanguageRoute path='/:lang/feedback/:token/:type?/:stars?/:fqa?' component={Feedback} />
        <MultiLanguageRoute path='/:lang/nps-email-rating/:token/:type?' component={NpsEmailRating} />
        

        
        <MultiLanguageRoute path='/:lang/feedbackAssigned/:token/' component={feedbackAssigned} />

        <MultiLanguageRoute path='/:lang/techinicalteams' component={TechinicalTeams} />
        <MultiLanguageRoute path='/:lang/techinicalteam-registration' component={TechinicalTeamRegistration} />
        <MultiLanguageRoute path='/:lang/techinicalteam-edit' component={TechinicalTeamEdit} />

        <MultiLanguageRoute path='/:lang/techinicalteamusers' component={TechinicalTeamUsers} />
        <MultiLanguageRoute path='/:lang/techinicalteamuser-registration' component={TechinicalTeamUserRegistration} />
        <MultiLanguageRoute path='/:lang/techinicalteamuser-edit' component={TechinicalTeamUserEdit} />

        <MultiLanguageRoute path='/:lang/techinicalteamorganizationcategories' component={TechinicalTeamOrganizationCategories} />
        <MultiLanguageRoute path='/:lang/techinicalteamorganizationcategory-registration' component={TechinicalTeamOrganizationCategoryRegistration} />
        <MultiLanguageRoute path='/:lang/techinicalteamorganizationcategory-edit' component={TechinicalTeamOrganizationCategoryEdit} />

        <MultiLanguageRoute path='/:lang/techinicalteamorganizations' component={TechinicalTeamOrganizations} />
        <MultiLanguageRoute path='/:lang/techinicalteamorganization-registration' component={TechinicalTeamOrganizationRegistration} />
        <MultiLanguageRoute path='/:lang/techinicalteamorganization-edit' component={TechinicalTeamOrganizationEdit} />

        <MultiLanguageRoute path='/:lang/categoryorganizations' component={CategoryOrganizations} />
        <MultiLanguageRoute path='/:lang/categoryorganization-registration' component={CategoryOrganizationRegistration} />
        <MultiLanguageRoute path='/:lang/categoryorganization-edit' component={CategoryOrganizationEdit} />
        <MultiLanguageRoute path='/:lang/roles' component={Roles} />
        <MultiLanguageRoute path='/:lang/role-registration' component={RoleRegistration} />
        <MultiLanguageRoute path='/:lang/role-edit' component={RoleEdit} />
        <MultiLanguageRoute path='/:lang/permissions' component={Permissions} />
        <MultiLanguageRoute path='/:lang/permission-registration' component={PermissionRegistration} />
        <MultiLanguageRoute path='/:lang/permission-edit' component={PermissionEdit} />

        <MultiLanguageRoute path='/:lang/account-confirmation/:token' component={accountConfirmation} />

        <MultiLanguageRoute path='/:lang/external-called-report/' component={ExternalCalledReport} />
        <MultiLanguageRoute path='/:lang/called-report-information/' component={CalledReportInformation} />
        
        
        <MultiLanguageRoute path='/:lang/organizationcalendars' component={OrganizationCalendars} />
        <MultiLanguageRoute path='/:lang/organizationcalendar-registration' component={OrganizationCalendarRegistration} />
        <MultiLanguageRoute path='/:lang/organizationcalendar-edit' component={OrganizationCalendarEdit} />

        <MultiLanguageRoute path='/:lang/organizationfields' component={OrganizationFields} />
        <MultiLanguageRoute path='/:lang/organizationfield-registration' component={OrganizationFieldRegistration} />
        <MultiLanguageRoute path='/:lang/organizationfield-edit' component={OrganizationFieldEdit} />

        <MultiLanguageRoute path='/:lang/assignedcalleds' component={Calleds} />
        <MultiLanguageRoute path='/:lang/assignedcalled-registration' component={AssignedCalledRegistration} />
        <MultiLanguageRoute path='/:lang/assignedcalled-edit' component={AssignedCalledEdit} />

        <MultiLanguageRoute path='/:lang/called-rejection' component={CalledRejection} />
        <MultiLanguageRoute path='/:lang/called-rejection-edit' component={CalledRejectionEdit} />

        <MultiLanguageRoute path='/:lang/archived-calleds/:q?' component={Calleds} />

        <MultiLanguageRoute path='/:lang/canceled-calleds/:q?' component={Calleds} />

        <MultiLanguageRoute path='/:lang/called-time-entries-registration' component={CalledTimeEntriesRegistration} />

        <MultiLanguageRoute path='/:lang/additional-called-cost-registration' component={AdditionalCalledCostRegistration} />
        <MultiLanguageRoute path='/:lang/additional-called-cost-edit' component={AdditionalCalledCostEdit} />
        <MultiLanguageRoute path='/:lang/statuses' component={Statuses} />
        <MultiLanguageRoute path='/:lang/status-registration' component={StatusRegistration} />
        <MultiLanguageRoute path='/:lang/status-edit' component={StatusEdit} />

        <MultiLanguageRoute path='/:lang/configs' component={Configs} />
        <MultiLanguageRoute path='/:lang/config-registration' component={ConfigRegistration} />
        <MultiLanguageRoute path='/:lang/config-edit' component={ConfigEdit} />
        <MultiLanguageRoute path='/:lang/calledservicesettings-edit' component={calledServiceSettings} />
        <MultiLanguageRoute path='/:lang/calledservicesettings' component={CalledServiceSettingsList} />

        <MultiLanguageRoute path='/:lang/called-report-configs' component={CalledReportConfigs} />
        <MultiLanguageRoute path='/:lang/called-report-config-registration' component={CalledReportConfigRegistration} />
        <MultiLanguageRoute path='/:lang/called-report-config-edit' component={CalledReportConfigEdit} />

        <MultiLanguageRoute path='/:lang/calledflows' component={CalledFlows} />
        <MultiLanguageRoute path='/:lang/calledflow-registration' component={CalledFlowRegistration} />
        <MultiLanguageRoute path='/:lang/calledflow-edit' component={CalledFlowEdit} />

        <MultiLanguageRoute path='/:lang/calledflowstatuses' component={CalledFlowStatuses} />
        <MultiLanguageRoute path='/:lang/calledflowstatus-registration' component={CalledFlowStatusRegistration} />
        <MultiLanguageRoute path='/:lang/calledflowstatus-edit' component={CalledFlowStatusEdit} />

        <MultiLanguageRoute path='/:lang/distributionstrategies' component={DistributionStrategies} />
        <MultiLanguageRoute path='/:lang/distributionstrategy-registration' component={DistributionStrategyRegistration} />
        <MultiLanguageRoute path='/:lang/distributionstrategy-edit' component={DistributionStrategyEdit} />

        <MultiLanguageRoute path='/:lang/organizationcategories' component={OrganizationCategories} />
        <MultiLanguageRoute path='/:lang/organizationcategory-registration' component={OrganizationCategoryRegistration} />
        <MultiLanguageRoute path='/:lang/organizationcategory-edit' component={OrganizationCategoryEdit} />


        <MultiLanguageRoute path='/:lang/organizationslas' component={OrganizationSlas} />
        <MultiLanguageRoute path='/:lang/organizationsla-registration' component={OrganizationSlaRegistration} />
        <MultiLanguageRoute path='/:lang/organizationsla-edit' component={OrganizationSlaEdit} />


        <MultiLanguageRoute path='/:lang/scheduleworkhours' component={ScheduleWorkHours} />
        <MultiLanguageRoute path='/:lang/scheduleworkhour-registration' component={ScheduleWorkHourRegistration} />
        <MultiLanguageRoute path='/:lang/scheduleworkhour-edit' component={ScheduleWorkHourEdit} />

        <MultiLanguageRoute path='/:lang/slacounttypes' component={SlaCountTypes} />
        <MultiLanguageRoute path='/:lang/slacounttype-registration' component={SlaCountTypeRegistration} />
        <MultiLanguageRoute path='/:lang/slacounttype-edit' component={SlaCountTypeEdit} />

        <MultiLanguageRoute path='/:lang/workhours' component={WorkHours} />
        <MultiLanguageRoute path='/:lang/workhour-registration' component={WorkHourRegistration} />
        <MultiLanguageRoute path='/:lang/workhour-edit/' component={WorkHourEdit} />
        <MultiLanguageRoute path='/:lang/workhour-edit-register/' component={WorkHourEditRegister} />
        
        <MultiLanguageRoute path='/:lang/holidays' component={Holidays} />
        <MultiLanguageRoute path='/:lang/holiday-registration' component={HolidayRegistration} />
        <MultiLanguageRoute path='/:lang/holiday-edit' component={HolidayEdit} />
        
        <MultiLanguageRoute path='/:lang/calledstatuses' component={CalledStatuses} />
        <MultiLanguageRoute path='/:lang/tab-called-report-information' component={TabCalledReportInformation} />
        
        <MultiLanguageRoute path='/:lang/default-actions' component={DefaultActions} />
        <MultiLanguageRoute path='/:lang/default-actions-registration' component={DefaultActionsRegistration} />
        <MultiLanguageRoute path='/:lang/default-actions-edit' component={DefaultActionsEdit} />


        <MultiLanguageRoute path='/:lang/authentication' component={AuthenticationList} />
        <MultiLanguageRoute path='/:lang/authentication-registration' component={AuthenticationRegistration} />
        <MultiLanguageRoute path='/:lang/authentication-edit' component={AuthenticationEdit} />

        <MultiLanguageRoute path='/:lang/integration-service' component={IntegrationServiceList} />
        <MultiLanguageRoute path='/:lang/integration-service-registration' component={IntegrationServiceRegistration} />
        <MultiLanguageRoute path='/:lang/integration-service-edit' component={IntegrationServiceEdit} />

        <MultiLanguageRoute path='/:lang/variable' component={IntegrationUserVariableList} />
        <MultiLanguageRoute path='/:lang/variable-registration' component={IntegrationUserVariableRegistration} />
        <MultiLanguageRoute path='/:lang/variable-edit' component={IntegrationUserVariableEdit} />

        <MultiLanguageRoute path='/:lang/called-closing-statuses' component={CalledClosingStatuses} />
        <MultiLanguageRoute path='/:lang/called-closing-statuses-registration' component={CalledClosingStatusRegistration} />
        <MultiLanguageRoute path='/:lang/called-closing-statuses-edit' component={CalledClosingStatusEdit} />
        
        <MultiLanguageRoute path='/:lang/priorities' component={Priorities} />
        <MultiLanguageRoute path='/:lang/priority-registration' component={PriorityRegistration} />
        <MultiLanguageRoute path='/:lang/priority-edit' component={PriorityEdit} />

        <MultiLanguageRoute path='/:lang/materials' component={Materials} />
        <MultiLanguageRoute path='/:lang/material-registration' component={MaterialRegistration} />
        <MultiLanguageRoute path='/:lang/material-edit' component={MaterialEdit} />

        <MultiLanguageRoute path='/:lang/organizationdepartaments' component={OrganizationDepartaments} />
        <MultiLanguageRoute path='/:lang/organizationdepartament-registration' component={OrganizationDepartamentRegistration} />
        <MultiLanguageRoute path='/:lang/organizationdepartament-edit' component={OrganizationDepartamentEdit} />

        <MultiLanguageRoute path='/:lang/organizationmaterials' component={OrganizationMaterials} />
        <MultiLanguageRoute path='/:lang/organizationmaterial-registration' component={OrganizationMaterialRegistration} />
        <MultiLanguageRoute path='/:lang/organizationmaterial-edit' component={OrganizationMaterialEdit} />

        <MultiLanguageRoute path='/:lang/organizationpatrimonies' component={OrganizationPatrimonies} />
        <MultiLanguageRoute path='/:lang/organizationpatrimony-registration' component={OrganizationPatrimonyRegistration} />
        <MultiLanguageRoute path='/:lang/organizationpatrimony-edit' component={OrganizationPatrimonyEdit} />

        <MultiLanguageRoute path='/:lang/relationshipcalled-registration' component={RelationshipCalledRegistration} />

        <MultiLanguageRoute path='/:lang/patrimonygroups' component={PatrimonyGroups} />
        <MultiLanguageRoute path='/:lang/patrimonygroup-registration' component={PatrimonyGroupRegistration} />
        <MultiLanguageRoute path='/:lang/patrimonygroup-edit' component={PatrimonyGroupEdit} />

        <MultiLanguageRoute path='/:lang/patrimonymaterials' component={PatrimonyMaterials} />
        <MultiLanguageRoute path='/:lang/patrimonymaterial-registration' component={PatrimonyMaterialRegistration} />
        <MultiLanguageRoute path='/:lang/patrimonymaterial-edit' component={PatrimonyMaterialEdit} />

        <MultiLanguageRoute path='/:lang/patrimonysubgroups' component={PatrimonySubGroups} />
        <MultiLanguageRoute path='/:lang/patrimonysubgroup-registration' component={PatrimonySubGroupRegistration} />
        <MultiLanguageRoute path='/:lang/patrimonysubgroup-edit' component={PatrimonySubGroupEdit} />

        <MultiLanguageRoute path='/:lang/portal' component={Portal} />
        <MultiLanguageRoute path='/:lang/portal-category/:id' component={Cardinformations} />
        <MultiLanguageRoute path='/:lang/knowledgebases-client' component={ClientKnowledgeBases} />
        <MultiLanguageRoute path='/:lang/knowledgebases-external' component={ExternalKnowledgeBases} />
        <MultiLanguageRoute path='/:lang/knowledgebases' component={KnowledgeBases} />
        <MultiLanguageRoute path='/:lang/knowledgebase-registration' component={KnowledgeBaseRegistration} />
        <MultiLanguageRoute path='/:lang/knowledgebase-edit' component={KnowledgeBaseEdit} />
        
        <MultiLanguageRoute path='/:lang/steps' component={Steps} />
        <MultiLanguageRoute path='/:lang/webhook' component={Webook} />
        <MultiLanguageRoute path='/:lang/units' component={unitList} />
        <MultiLanguageRoute path='/:lang/unit-registration' component={UnitRegistration} />
        <MultiLanguageRoute path='/:lang/unit-edit' component={UnitEdit} />
        
        <MultiLanguageRoute path='/:lang/organizationchecklists' component={OrganizationChecklists} />
        <MultiLanguageRoute path='/:lang/organizationchecklist-registration' component={OrganizationChecklistRegistration} />
        <MultiLanguageRoute path='/:lang/organizationchecklist-edit' component={OrganizationChecklistEdit} />

        <MultiLanguageRoute path='/:lang/calledlistdatas' component={CalledListDatas} />
        <MultiLanguageRoute path='/:lang/calledlistdata-registration' component={calledlistdataRegistration} />
        <MultiLanguageRoute path='/:lang/calledlistdata-edit' component={calledlistdataEdit} />

        <MultiLanguageRoute path='/:lang/reportfcr' component={FcrReport} />
        
        <MultiLanguageRoute path='/:lang/my-plan' component={MyPlan} />
        <MultiLanguageRoute path='/:lang/unavailable' component={Unavailable} />
        <MultiLanguageRoute path='/:lang/usererrorreport' component={UserErrorReport} />
        
        <MultiLanguageRoute path='/:lang/additional-service-config' component={AdditionalCalledCostItems} />
        <MultiLanguageRoute path='/:lang/additionalcalledcostitem-registration' component={AdditionalCalledCostItemRegistration} />
        <MultiLanguageRoute path='/:lang/additionalcalledcostitem-edit' component={AdditionalCalledCostItemEdit} />
        <MultiLanguageRoute path='/:lang/additionalcalledcostreason-registration' component={AdditionalCalledCostItemReasonRegistration} />
        <MultiLanguageRoute path='/:lang/additionalcalledcostreason-edit' component={AdditionalCalledCostItemReasonEdit} />
        <MultiLanguageRoute path='/:lang/additionalcalledcosttype-registration' component={AdditionalCalledCostItemTypeRegistration} />
        <MultiLanguageRoute path='/:lang/additionalcalledcosttype-edit' component={AdditionalCalledCostItemTypeEdit} />

        <MultiLanguageRoute path='/:lang/organization-field-report-block-config' component={OrganizationFieldReportBlocks} />
        <MultiLanguageRoute path='/:lang/external-user-registration' component={ExternalUserRegistration} />
        <MultiLanguageRoute path='/:lang/admin/login' component={LoginAdmHirer} />
        <MultiLanguageRoute path='/:lang/admin/account-confirmation/:token?' component={AccountConfirmationAdmHirer} />
        <MultiLanguageRoute path='/:lang/admin/hirer' component={MultiHirer} />
        <MultiLanguageRoute path='/:lang/admin/hirer-register' component={MultiHirerRegister} />
        <MultiLanguageRoute path='/:lang/admin/hirer-edit' component={MultiHirerEdit} />
        <MultiLanguageRoute path='/:lang/admin/forgot-password' component={ForgotPasswordAdmin} />
        <MultiLanguageRoute path='/:lang/admin' component={LoginAdmHirer} />
        <MultiLanguageRoute path="/:lang/enableagents" component={EnableAgents} />
        <MultiLanguageRoute path="/:lang/rulesattendance-registration" component={RulesAttendanceRegistration} />
        <MultiLanguageRoute path="/:lang/rulesattendance-edit" component={RulesAttendanceEdit} />
        <MultiLanguageRoute path="/:lang/rulesattendance" component={RulesAttendance} />
        <MultiLanguageRoute path="/:lang/attendance" component={Attendance} />
        <MultiLanguageRoute path="/:lang/phoneregistrationrequests" component={PhoneRegistrationRequests} />
        <MultiLanguageRoute path="/:lang/phoneregistrationrequests-edit" component={PhoneRegistrationRequestsEdit} />
        <MultiLanguageRoute path="/:lang/phoneregistrationrequests-registration" component={PhoneRegistrationRequestsRegistration} />
        <MultiLanguageRoute path="/:lang/omnichannel" component={Omnichannel} />
        <MultiLanguageRoute path="/:lang/omnichannel-observe/:id" component={OmnichannelObserve} />

        
        <MultiLanguageRoute path="/:lang/chat-attendance-list" component={ToTalkChatAttendance} />
        <MultiLanguageRoute path="/:lang/channel" component={channel} />
        <MultiLanguageRoute path="/:lang/channel-registration" component={channelRegistration} />
        <MultiLanguageRoute path="/:lang/channel-edit" component={channelEdit} />
        <MultiLanguageRoute path="/:lang/customers" component={customerList} />
        <MultiLanguageRoute path="/:lang/customer-registration" component={customerRegistration} />
        <MultiLanguageRoute path="/:lang/customer-edit" component={customerEdit} />
        <MultiLanguageRoute path="/:lang/companies" component={companyList} />
        <MultiLanguageRoute path="/:lang/company-registration" component={companyRegistration} />
        <MultiLanguageRoute path="/:lang/company-edit" component={companyEdit} />
        <MultiLanguageRoute path="/:lang/webchats" component={webchatList} />
        <MultiLanguageRoute path="/:lang/webchat-registration" component={webchatRegistration} />
        <MultiLanguageRoute path="/:lang/webchat-edit" component={webchatEdit} />
        <MultiLanguageRoute path="/:lang/chatbots" component={chatbotList} />
        <MultiLanguageRoute path="/:lang/2talk-chat-report" component={TwoTalkChatReport} />
        <MultiLanguageRoute path="/:lang/chatbot-registration" component={chatbotRegistration} />
        <MultiLanguageRoute path="/:lang/chatbot-edit" component={chatbotEdit} />
        <MultiLanguageRoute path="/:lang/chatclassifications" component={chatClassifications} />
        <MultiLanguageRoute path="/:lang/chatclassifications-edit" component={chatClassificationsEdit} />

        <MultiLanguageRoute path="/:lang/params" component={ParamsList} />
        <MultiLanguageRoute path="/:lang/params-registration" component={paramsRegistration} />
        <MultiLanguageRoute path="/:lang/params-edit" component={paramsEdit} />


        <MultiLanguageRoute path="/:lang/quick-responses" component={quickresponseList} />
        <MultiLanguageRoute path="/:lang/quick-responses-registration" component={quickresponseRegistration} />
        <MultiLanguageRoute path="/:lang/quick-responses-edit" component={quickresponseEdit} />
        
        <MultiLanguageRoute path="/authorize" exact component={Authorize} />
        <MultiLanguageRoute path="/:lang/authorize" exact component={Authorize} />

        <MultiLanguageRoute path="/authorize/example" exact component={AuthorizeExample} />
        <MultiLanguageRoute path="/:lang/authorize/example" exact component={AuthorizeExample} />

        <MultiLanguageRoute path="*" component={Page404}/>
      </Switch>
    </BrowserRouter>
  );
}
