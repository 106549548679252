import { ChevronLeft, Dashboard, ExitToApp, Menu, QueuePlayNext, Search } from '@mui/icons-material';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import InventoryIcon from '@mui/icons-material/Inventory';
import SchoolIcon from '@mui/icons-material/School';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { AppBar, Button, Divider, Drawer, Grid, IconButton, InputBase, ListItem, ListItemIcon, ListItemText, Paper, TextField, Toolbar, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import clsx from 'clsx';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AbilityContext, Can } from '../../config/ability-context';
import * as actionsHeaderMenuActions from '../../redux/actions/actions-header-menu';
import * as headerMenuActions from '../../redux/actions/header-menu';
import * as userSessionActions from '../../redux/actions/user-session';
import Api from '../../services/api';
import { Styles } from "../../styles/material-styles";
import GroupMenu from '../group-menu';
import GroupMenu2 from '../group-menu/index-2';
import LogoSmall from '../logo/logo-small';
import LogoSmall2 from '../logo/logo-small-2';
import NotificationMenu from '../notification-menu';
import UserInfo from '../user-info';
import UserMenu from '../user-menu';
import UserMenuMobile from '../user-menu-mobile';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CachedIcon from '@mui/icons-material/Cached';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ClassIcon from '@mui/icons-material/Class';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import MailIcon from '@mui/icons-material/Mail';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SpeakerGroupIcon from '@mui/icons-material/SpeakerGroup';
import { CircularProgress } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import ReactFlagsSelect from "react-flags-select";
import { FormattedMessage, injectIntl } from 'react-intl';
import NumberFormat from "react-number-format";
import styled from "styled-components";
import MyMaterialTable from '../../components/table';
import { calledExists, searchById } from '../../services/called.service';
import { getOrganizations } from '../../services/organization.service';
import * as Service from "../../services/organizationsetting.service";
import { Overlay } from "../../styles/global";
import { PapiroConsole } from "../../utils/papiroConsole";
import ModalRating from '../ModalRating';
import AppMenu from '../appmenu/appmenu';
import Rightappmenu from "../appmenu/rightappmenu";
import CustomizedSnackbars from "../material-snackbars";
import TransitionsModal from '../modal/modal';
import QRCodeReader from "../qrcode-reader/qrcode-reader";
//import SimpleAutoComplete from '../select/simple';
import SimpleAutoComplete from '../auto-complete/autocomplete';
import './header-menu.css';
//import { useChatHubContext, ChatHubContext } from "../../contexts/chatHubContext";



function ListItemLink(props) {
  const { icon, primary, to, state } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <Link to={{
      pathname: to,
      state: state
    }} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}


ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  state: PropTypes.string,
};

const useStyles = makeStyles(theme =>
  createStyles({
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#97c05c',
    },
    nested: {
      paddingLeft: theme.spacing(4)
    }
  }),
)
const drawerWidth = 240;
function click(i) {
  //console.log(i);
}

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: #FFF;
  }
  & .MuiOutlinedInput-notchedOutline:hover {
    border-color: #CCC;
  }
  & .MuiInputLabel-root {
    color: #FFF;
  }
  & .MuiOutlinedInput-input {
    color: #FFF;
  }
  & .MuiOutlinedInput-root {
    border-color: #FFF;
  }
  & .MuiOutlinedInput-root .Mui-focused {
    border: 1px solid #FFF;
  }
  & .MuiInputBase-colorPrimary {
    border-color: #FFF;
  }
`;

class HeaderMenu extends Component {

  constructor(props) {
    super(props);


    /*const { userSession } = this.props

    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }*/

    this.modalRating = null;

    let langCode = 'BR'
    let language = window.location.pathname.split('/')[1]
    if (language === 'en')
      langCode = 'US'
    else if (language === 'es')
      langCode = 'ES'
    else
      langCode = 'BR'

      this.is2TalkAvaliable = false;
      if(this.props.userSession && this.props.userSession.user && this.props.userSession.user.productsavailables){
        var productsavailables = this.props.userSession.user.productsavailables;
        productsavailables.forEach(element => {
          if (element.id == 2) {
            this.is2TalkAvaliable = true
          }
        });
      }

    this.state = {
      loading: false,
      redirect: false,
      subsopen: [false, false, false, false],
      matches: window.matchMedia("(max-width: 660px)").matches,
      style: { paddingLeft: '30px', color: '#ffffff', fontSize: '20px' },
      styleMobile: { paddingLeft: '15px', color: '#ffffff', fontSize: '15px' },
      modalopen: false,
      modalgroup: false,
      WindowSize: window.innerWidth,
      marginLeft: window.innerWidth > 540 ? ((window.innerWidth - 540) * 0.8) : ((window.innerWidth - 310) * 0.8),
      OrganizationListMenu: [],
      variantDot: '',
      listNotification: [],
      qtdNotification: 0,
      openSteps: false,
      orgId: 0,
      openCheckEmail: false,
      OrganizationList: [],
      organizationCheckEmail: 0,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      displayfinished: false,
      languageCode: langCode,
      searchCalledId: "",
      searchCalled: "",
      colorTheme: Api != null ? Api.getPrimaryColorTheme() : "#3F51B4",
      secondaryColorTheme: Api != null ? Api.getSecondaryColorTheme() : "#277afe",
      logo: Api != null ? Api.getLoginImage() : null,
      is2TalkAvaliable: this.is2TalkAvaliable,
      isNotClient: this.props.userSession != null && this.props.userSession.user != null && this.props.userSession.user.userrole != null ? this.props.userSession.user.userrole.some((item) => item.role.roletype != 3) : false,
      unratedCalledsListState : [], 
      modalRatingOpen : false,
      showMoreData : null,
      organizationIdRatingCalleds: null
    
    };
    // console.log(props);
    this.handleResize = this.handleResize.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.QRCode = this.QRCode.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openModalGroup = this.openModalGroup.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModalGroup = this.closeModalGroup.bind(this);
    this.getTutorial = this.getTutorial.bind(this);
    this.selectLanguage = this.selectLanguage.bind(this);
    this.handleSearchCalledId = this.handleSearchCalledId.bind(this)
    this.goToCalled = this.goToCalled.bind(this)
    this.handleChangeSearchCalled = this.handleChangeSearchCalled.bind(this)
    this.onSearchCalled = this.onSearchCalled.bind(this)
    this.logout = this.logout.bind(this)
    this.LoadingNotification = this.LoadingNotification.bind(this)

    this.setAlertNotification = this.setAlertNotification.bind(this)

  }


  LoadingNotification(item = false) {
    PapiroConsole.log(item)
    PapiroConsole.log("item loading")
    this.setState({ loading: item })

  }

  setAlertNotification(notificationVariant, notificationMessage) {

    if (notificationVariant && notificationMessage) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: notificationVariant,
        notificationMessage: notificationMessage,
      });
    }




  }

  logout = _ => {
    this.setState({ loading: true })
    this.props.removeUserSession();

    let language = window.location.pathname.split('/')[1]
    if (language != "pt" && language != "es" && language != "en")
      language = "pt"

    Api.get("/users/removeClaims")
      .then(result => {
        if (Api && Api.resetToken) { Api.resetToken(); }
      })
      .catch(err => {
        if (Api && Api.resetToken) { Api.resetToken(); }
      })
    this.props.history.push(`/${language}/`)
  }

  handleSearchCalledId = async (e) => {
    PapiroConsole.log("=== handleSearchCalledId ===")
    PapiroConsole.log(e)
    if (e.target.value != null && typeof e.target.value !== 'undefined') {
      this.setState({ searchCalledId: e.target.value })
    }
  }

  goToCalled = async (newTab = false) => {
    PapiroConsole.log("=== goToCalled ===")
    PapiroConsole.log(this.state.searchCalledId)
    const intl = this.props.intl;
    if (this.state.searchCalledId && this.state.searchCalledId.length > 0) {
      this.setState({ loading: true })
      let result = await calledExists(this.state.searchCalledId);
      if (result.success) {
        this.setState({ loading: false })
        if (result.data != null && typeof result.data !== 'undefined' && result.data === true) {
          if (!newTab) {
            this.props.history.push(`/${this.props.match.params.lang}/called-accompaniment/${this.state.searchCalledId}`);
            if (this.props.history && this.props.history.location && this.props.history.location && this.props.history.location.pathname && this.props.history.location.pathname.length > 0) {
              PapiroConsole.log("=== qual path? ===")
              let pathList = this.props.history.location.pathname.split('/')
              PapiroConsole.log(pathList)
              PapiroConsole.log(pathList.length)
              if (pathList && pathList.length >= 2) {
                if (pathList[2] == 'called-accompaniment') {
                  this.setState({ searchCalledId: "" })
                  //await this.handleReload()
                  window.location.reload()
                }
              }
            }
          } else {
            window.open(`${window.location.origin}/${this.props.match.params.lang}/called-accompaniment/${this.state.searchCalledId}`, '_blank', 'noopener,noreferrer')
          }
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "called.does.not.exist" }),
          });
        }
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.response && result.response.data && result.response.data.errors
              ? result.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        Api.kickoff(result);
      }
    }
  }

  NumberFormatCustom(props) {
    PapiroConsole.log("=== NumberFormatCustom ===")
    PapiroConsole.log(props)
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        isNumericString
      />
    );
  }

  selectLanguage = async (code) => {
    await this.setState({ languageCode: code })
    let urlCode = 'pt'
    let headerCode = 'pt-BR'
    if (code === "US") {
      urlCode = 'en'
      headerCode = 'en-US'
    } else if (code === "ES") {
      urlCode = 'es'
      headerCode = 'es-ES'
    }

    Api.setHeaderAcceptLanguage(headerCode)
    this.props.history.push(`/${urlCode}/calleds`);
    window.location.reload()
  }

  QRCode() {
    this.openModal();
  }

  calculate() {
    if (this.state.WindowSize > 540) {
      this.setState({ marginLeft: ((this.state.WindowSize - 540) * 0.8) });
    } else {

      this.setState({ marginLeft: ((this.state.WindowSize - 310) * 0.8) });
    }


  }

  closeNotification = () => {
    this.setState({ openNotification: false });
  }

  changeValuesCheckEmail = async (stateName, value, text = '') => {
    await this.setState({ organizationCheckEmail: value });
  }

  getOrganizations = async () => {

    PapiroConsole.log("OrganizationList")

    var result = await Service.getOrganizations(0);
    if (result.success) {
      this.setState({ OrganizationList: result.data });
    } else {
      // console.log("erro get organizations")
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  openModal() {
    this.setState({ modalopen: true });

  }
  async closeModal() {
    this.setState({ modalopen: false });

  }

  openModalGroup() {
    this.setState({ modalgroup: true });

  }
  async closeModalGroup() {
    this.setState({ modalgroup: false });

  }


  handleClick = (i) => {

  }
  handleResize(WindowSize, event) {
    this.setState({ WindowSize: window.innerWidth })

    this.calculate();
  }
  componentWillReceiveProps(nextProps) {

    PapiroConsole.log("entrei no componentWillReceiveProps do header")
    if (nextProps.location.state === "reload") {
      this.props.location.state = ""
      this.componentDidMount();
    }
  }

  async componentDidMount() {

    PapiroConsole.log("entrei no did mount do header")

    window.addEventListener("resize", this.handleResize);
    const handler = (e) => {
      this.setState({ matches: e.matches })
    };
    window.matchMedia("(max-width: 660px)").addListener(handler);

    this.getTutorial();
    //await this.getDisplayFinishedCalls();

  }

  openCheckEmail = async () => {
    this.setState({ openCheckEmail: true, loading: true });
    await this.getOrganizations();
    this.setState({ openCheckEmail: true, loading: false });
  }

  closeCheckEmail = () => {
    this.setState({ openCheckEmail: false });
  }

  async getDisplayFinishedCalls() {
    await Api.get(`configs/finishedcalleds/`)
      .then(async result => {
        if (result.data.success) {
          this.setState({ displayfinished: result.data.data })
        }
      });
  }

  getTutorial() {
    //endpoint antigo que pegava as notificações pro usuário, provavelmente vai ser descontinuado


    /*
    PapiroConsole.log("=== getTutorial ===")
    Api.get(`/notification/tutorial`)
      .then(result => {
        if (result.data.success) {
          this.setState({ loading: false, listNotification: result.data.data });
          let count = 0;
          var alert = false;
          this.state.listNotification.map((item, key) => {
            item.notification.map((itemDois, key) => {
              if (itemDois.required == true && itemDois.finish == false) {
                alert = true;
              }
              if (itemDois.finish == false) {
                count++;
              }
            });
          });
          this.setState({ loading: false, qtdNotification: count });
          //console.log(alert);
          if (alert == true) {
            window.onload = function (e) {
              document.getElementById('btnNotification').click();
            }
          }
        }
      });*/

  }

  componentWillUnmount() {
    window.addEventListener("resize", null);
  }

  handleDrawerOpen = () => {
    this.props.location.state = "";
    if (this.props.headerMenu && this.props.headerMenu.open != null) {
      if (this.props.headerMenu.open)
        this.props.toggle(false)
      else
        this.props.toggle(true)
    } else
      this.props.toggle(true);

  };

  handleActionsDrawerOpen = _ => {
    this.props.location.state = ""
    if (this.props.actionsHeaderMenu && this.props.actionsHeaderMenu.open != null) {
      if (this.props.actionsHeaderMenu.open)
        this.props.toggleActionMenu(false)
      else
        this.props.toggleActionMenu(true)
    } else
      this.props.toggleActionMenu(true)
  }

  handleActionsDrawerClose = () => {
    this.props.location.state = "";
    this.props.toggleActionMenu(false);
  };

  getOrganizationsReload = async () => {
    this.setState({ loading: true });
    var result = await getOrganizations();
    if (result.success) {
      if (result.data && result.data.length > 0) {
        PapiroConsole.log("entrei aqui nas orgs")
        this.setState({ OrganizationList: result.data });
        const { userSession } = this.props
        if (userSession && userSession.user) {
          let user = userSession.user
          user.userorganizations = result.data
          this.props.addUserSession(user);
        }
      }
      this.setState({ loading: false });
    } else {
      // console.log("erro get organizations")
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  handleReload = async () => {

    const { router, params, match, location, history, routes } = this.props
    await this.getOrganizationsReload()
    const current = location.pathname;
    this.props.location.state = "reload";
    this.props.reload(true);

  }
  handleDrawerClose = () => {
    this.props.location.state = "";
    this.props.toggle(false);
  };

  checkEmail = async () => {
    const intl = this.props.intl;

    this.setState({ loading: true });

    await Api.get('/emails/organization/' + this.state.organizationCheckEmail.id).then(result => {

      if (result.data.success) {
        if (result.data.data > 1) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "called.list.notification.was.created" }) + result.data.data + intl.formatMessage({ id: "called.list.notification.called.with.success" }),
            resultList: []
          });
          if (window.location.pathname.split('/')[2] == "calleds") {
            this.props.location.state = "reload";
            this.props.reload(true);
          }

        } else if (result.data.data == 1) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "called.list.notification.was.created.plural" }) + " " + result.data.data + " " + intl.formatMessage({ id: "called.list.notification.called.with.success.plural" }),
            resultList: []
          });

          if (window.location.pathname.split('/')[2] == "calleds") {
            this.props.location.state = "reload";
            this.props.reload(true);
          }
        } else {

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'info',
            notificationMessage: intl.formatMessage({ id: "called.list.notification.empty.mailbox" }),
            resultList: []
          });
          Api.kickoff(result);
        }

        this.componentDidMount();
      }
    }).catch(err => {
      // console.log(err);
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(err);

    });;

    this.setState({ loading: false });
  }

  handleChangeSearchCalled = (e) => {
    const regex = /^[0-9\b]+$/;
    const { name, value } = e.target
    if (value === "" || regex.test(value))
      this.setState({ searchCalled: value })
  }

  onSearchCalled = async _ => {
    if (this.state.searchCalled.length > 0) {
      const { path } = this.props.match
      const { id } = this.props.match.params
      if (path != null && path.indexOf('/called-view/') != -1 && id != null && id == this.state.searchCalled) {

      } else {
        this.setState({ loading: true })
        let response = await searchById(this.state.searchCalled)
        if (response != null && response.success != null && response.success && response.data != null && response.data) {
          this.setState({ loading: false })
          this.props.history.push(`/${this.props.match.params.lang}/called-view/${this.state.searchCalled}`);
        } else {
          const intl = this.props.intl
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(response)
        }
      }
    } else {
      this.setState({ notification: true, notificationVariant: 'error', notificationMessage: 'Insira o número do chamado' })
    }
  }

  


  getItens = async (page = 1, organizationId = null, calledId = null) => {


    PapiroConsole.log("this.state.modalRatingOpen")
    PapiroConsole.log(this.state.modalRatingOpen)



    PapiroConsole.log("item aqui 16")
    PapiroConsole.log(organizationId)
    PapiroConsole.log(this.state.organizationIdRatingCalleds)

    //quando eu clicar no verificar ( primeira vez que entra no método)
    if(organizationId ){
        this.setState({organizationIdRatingCalleds : organizationId })
    }
    else{
      organizationId = this.state.organizationIdRatingCalleds ? this.state.organizationIdRatingCalleds : null ;
    }
   
    let unratedCalledsList = []
    let url = `/calleds/classifications/dapper/organization/${organizationId}/user/${this.props.userSession.user.id}/page/${page}` ;

    this.setState({loading : true})
    await Api.get(url)
      .then((result) => {
        if (result.data.success) {

          var orgData = []

          if (this.props.userSession && this.props.userSession.user && this.props.userSession.user.userorganizations && this.props.userSession.user.userorganizations.length > 0) {
            orgData = this.props.userSession.user.userorganizations
          }

          PapiroConsole.log("item aqui 17")
          PapiroConsole.log(orgData)
          PapiroConsole.log(result.data.data)

          result.data.data.forEach((element) => {
            if (element != null) {
              if (element.calledclassification == null) {
                const displayassignedinfomode = orgData.find(org => org.id == element.organization.id)?.organizationsettings?.displayassignedinfomode
                //garantir que na query não venha elementos repetidos
                const exists = unratedCalledsList.some(newItem => newItem.id === element.id);
                if (!exists) {
                  unratedCalledsList.push({ ...element, displayassignedinfomode: displayassignedinfomode })
                }
              }
            }
          });

          //código feito devido aos ids dos chamados virem repetidos na classificação de chamados
          // const idsUnratedCalledsListState = new Set(this.state.unratedCalledsListState.map(item => item.id));
          // const filteredArray = unratedCalledsList && unratedCalledsList.length && unratedCalledsList.length > 0 ? unratedCalledsList.filter(item => !idsUnratedCalledsListState.has(item.id)) : [] ;
         
          //Significa que o usuário apertou o mostrar mais 
          PapiroConsole.log("print de variaveis")
          PapiroConsole.log(page)
          PapiroConsole.log(this.state.unratedCalledsListState)
          PapiroConsole.log(unratedCalledsList)
          PapiroConsole.log(this.state.modalRatingOpen)


          if(page > 1){
            unratedCalledsList = [...this.state.unratedCalledsListState, ...unratedCalledsList]
          }
          
          var requiredratingcalleds = orgData.find(org => org.id == organizationId)?.organizationsettings?.requiredratingcalleds
          this.setState({ unratedCalledsListState: [...unratedCalledsList] })

          if (unratedCalledsList.length !== 0) {
           

            this.setState({ modalRatingOpen: unratedCalledsList.length !== 0 }, () => {
              this.modalRating = <ModalRating
                data={unratedCalledsList}
                onClose={() => this.closeModalRating()}
                requiredratingcalleds={requiredratingcalleds}
                organizationId={organizationId}
                createdUser={this.userid}
                setLoading={this.setLoading}
                setMessage={this.setMessage}
                getFinishedCalleds={this.getItens}
                showMore={result.data.data.length >= 10}
                updateList={(newList) => this.setState({ unratedCalledsListState: newList })}
                selectedCalled = {calledId}
                //adm={this.AdmOrganization}
              />
            });


          }

            this.setState({loading : false})
          
        } else {
          const intl = this.props.intl
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }

      })
      .catch((err) => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);
      });
  }

  closeModalRating = () => {
    this.setState({
      modalRatingOpen: false,
      unratedCalledsListState: []
    });

   
  }

  setLoading = (isLoad) => {
    this.setState({
      loading: isLoad
    })
  }

  setMessage = (open, variant, message) => {
    this.setState({
      openNotification: open,
      notificationVariant: variant,
      notificationMessage: message
    });
  }


  render() {
    const intl = this.props.intl;

    const { classes, headerMenu, actionsHeaderMenu, userSession, history, location, lastCalledsQueue } = this.props;

    const primaryTheme = createTheme({
      palette: {
        primary: {
          main: this.state.colorTheme
        },
        secondary: {
          main: this.state.secondaryColorTheme
        }
      }
    })

    const theme = createTheme({
      overrides: {
        MuiIconButton: {
          root: {
            '&:hover': {
              backgroundColor: "$labelcolor"
              /*padding : "3px",
              borderRadius: "10px"*/
            }
          },

        }

      }
    })

    const divStyle = {
      day: {
        backgroundColor: "inherit !important",

        "&:focus": {
          outline: "none"
        },
        ".MuiIconButtonRoot": {

          padding: "0px"

        }
      }
    };
    const appMenuItems = [];

    const actionsMenuItems = [];

    this.isAdm = false;
    this.isClient = true;
    this.isAdmOrg = false;
    this.isAdmGlobal = false;
    this.isAdmGroup = false;
    this.isTec = false;
    this.isOnlyCoordOrg = false

    if (this.state.modalRatingOpen === false) {
      this.modalRating = null;
    }

    if (userSession != null && userSession.user != null && userSession.user.userrole != null) {
      userSession?.user?.userrole.map((item, key) => {

        if (item.role.roletype != 3) {
          this.isClient = false;
        }

        if (item.role.roletype == 0 || item.role.roletype == 1 || item.role.roletype == 6 || item.role.roletype == 4) {
          this.isAdm = true;
          if (item.role.roletype == 0) {
            this.isAdmGlobal = true;
          }
        }
        if (item.role.roletype == 1 && item.role.roletype != 0 && !this.isAdmGlobal) {
          this.isAdmOrg = true;
        }
        if (item.role.roletype == 4 && item.role.roletype !== 1 && item.role.roletype != 0 && !this.isAdmGlobal) {
          console.log(item.role.roletype)
          console.log("item.role.roletype")
          this.isOnlyCoordOrg = true;
        }
        if (item.role.roletype == 2) {
          this.isTec = true;
        }
      })
    }

    if (this.isAdmOrg) {
      this.isOnlyCoordOrg = false
    }

    let searchCalleds = actionsMenuItems.push({
      name: "Consultar chamados",
      Icon: Search,
      isButton: true
    })

    actionsMenuItems[searchCalleds - 1].items = []

    actionsMenuItems[searchCalleds - 1].items.push({
      component: <Paper
        elevation={3}
        component="form"
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: 400,
          padding: '0 0 0 2px'
        }} >
        &nbsp;#
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Digite o número do chamado"
          inputProps={{ 'aria-label': 'digite o numero do chamado' }}
          onChange={this.handleChangeSearchCalled}
          value={this.state.searchCalled} />
        <IconButton
          onClick={this.onSearchCalled}
          sx={{
            p: '10px',
            backgroundColor: '#3F51B4',
            borderRadius: '0 4px 4px 0',
            "&:hover": {
              backgroundColor: 'rgba(63, 81, 180, .8)'
            }
          }}
          aria-label="search">
          <Search sx={{ color: '#fff' }} />
        </IconButton>
      </Paper>,
      isButton: false
    })

    if (lastCalledsQueue != null && lastCalledsQueue != null && lastCalledsQueue.items != null) {
      const lastCalledsQueueKeys = Object.keys(lastCalledsQueue.items)
      //lastCalledsQueueKeys.sort((a, b) => b.localeCompare(a))

      let lastCalleds = actionsMenuItems.push({
        name: "Últimos chamados acessados",
        Icon: QueuePlayNext,
        isButton: true
      })

      actionsMenuItems[lastCalleds - 1].items = []

      lastCalledsQueueKeys.forEach((key) => {
        if (lastCalledsQueue.items.hasOwnProperty(key)) {
          const item = lastCalledsQueue.items[key]
          actionsMenuItems[lastCalleds - 1].items.push({
            name: `#${item.id} - ${item.title}`,
            link: `/${this.props.match.params.lang}/called-accompaniment/${item.id}`,
            state: `called${item.id}`,
            isButton: true
          })
        }
      })
    }

    actionsMenuItems.push({
      name: 'Logout',
      Icon: ExitToApp,
      onClick: this.logout,
      isButton: true
    })

    let dashboards = appMenuItems.push({
      name: this.props.intl.formatMessage({ id: "dashboards" }),
      Icon: Dashboard,
    });

    appMenuItems[dashboards - 1].items = [];

    appMenuItems[dashboards - 1].items.push({
      name: this.props.intl.formatMessage({ id: "menu.dashboards.operation" }),
      link: `/${this.props.match.params.lang}/dashboard`,
      state: "operationdashboard",
    });

    if (this.isAdmGlobal || this.isAdm || this.isAdmGroup || this.isAdmOrg) {
      appMenuItems[dashboards - 1].items.push({
        name: this.props.intl.formatMessage({ id: "menu.dashboards.productivity" }),
        link: `/${this.props.match.params.lang}/productivitydashboard`,
        state: "productivitydashboard",
      });

      appMenuItems[dashboards - 1].items.push({
        name: this.props.intl.formatMessage({ id: "menu.dashboards.quality" }),
        link: `/${this.props.match.params.lang}/qualitydashboard`,
        state: "qualitydashboard",
      });
    }

    if (this.context.can('List', 'Called')) {
      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "called.header" }),
          link: `/${this.props.match.params.lang}/calleds`,
          Icon: QueuePlayNext,
          state: "calleds",
        });
    }

    if (this.context.can('Open', 'Called')) {
      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "portal" }),
          link: `/${this.props.match.params.lang}/portal`,
          Icon: DashboardCustomizeIcon,
          state: "portal",
        });
    }

    if (this.context.can('List', 'AssignedCalled')) {
      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "called.assing" }),
          link: `/${this.props.match.params.lang}/assignedcalleds`,
          Icon: EventSeatIcon,
          state: "assignedcalleds",
        });
    }



    if (this.context.can('List', 'CalledRejection')) {
      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "called.rejection" }),
          link: `/${this.props.match.params.lang}/called-rejection`,
          Icon: DesktopAccessDisabledIcon,
          state: "calledrejection",
        });
    }

    if ((this.isAdmGlobal || this.isAdm || this.isAdmGroup || this.isAdmOrg || this.isTec)) {
      appMenuItems.push({
        name: this.props.intl.formatMessage({ id: "called.finished" }),
        link: `/${this.props.match.params.lang}/finishedCalleds`,
        Icon: CallEndIcon,
        state: "finishedCalleds",
      });
    }
    if (userSession?.user?.canuserviewarchivedcalleds) {

      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "archived.calleds" }),
          link: `/${this.props.match.params.lang}/archived-calleds`,
          Icon: InventoryIcon,
          state: "archivedcallleds",
        });


    }

    appMenuItems.push(
      {
        name: this.props.intl.formatMessage({ id: "canceled.calleds" }),
        link: `/${this.props.match.params.lang}/canceled-calleds`,
        Icon: DisabledByDefaultIcon,
        state: "canceledcalleds",
      });

    if (this.context.can('List', 'CalledClassification')) {
      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "menu.called.classifications" }),
          link: `/${this.props.match.params.lang}/calledclassifications`,
          Icon: ClassIcon,
          state: "calledclassifications",
        });
    }

    if (this.isClient) {
      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "knowledgebase" }),
          link: `/${this.props.match.params.lang}/knowledgebases-client`,
          Icon: EmojiObjectsIcon,
          state: "knowledgebasesclient",
        });
    }
    else {
      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "knowledgebase.panel" }),
          link: `/${this.props.match.params.lang}/knowledgebases-client`,
          Icon: SchoolIcon,
          state: "knowledgebasesclient",
        });

    }

    if (this.isTec && !this.isAdmGlobal && !this.isAdm) {

      var servicegroup = appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "reports" }),
          Icon: AssignmentIcon
        });
      appMenuItems[servicegroup - 1].items = [];

      if (appMenuItems[servicegroup - 1] && appMenuItems[servicegroup - 1].items != null) {
        if (this.context.can('List', 'CalledReport')) {

          appMenuItems[servicegroup - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "called.report.header" }),
              link: `/${this.props.match.params.lang}/calledreports`,
              //Icon: QueuePlayNext,
              state: "calledreports",
            });

        }

        if (this.context.can('List', 'CalledReport')) {

          appMenuItems[servicegroup - 1].items.push(
            {
              name: intl.formatMessage({ id: "hours.reported" }),
              link: `/${this.props.match.params.lang}/hours-report`,
              //Icon: QueuePlayNext,
              state: "hoursreport",
            });


        }

        if (this.context.can('List', 'CalledReport')) {

          appMenuItems[servicegroup - 1].items.push(
            {
              name: intl.formatMessage({ id: "organizations.categories.report" }),
              link: `/${this.props.match.params.lang}/organizations-categories-report`,
              //Icon: QueuePlayNext,
              state: "organizationscategoriesreport",
            });

        }







      }





    }

    if (this.isAdmGlobal) {

      //if (this.context.can('List', 'Nps')) {
      appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "nps.search" }),
          link: `/${this.props.match.params.lang}/nps-search`,
          Icon: ThumbsUpDownIcon,
          state: "npssearchs",
        });
      //}

    }

    //Fazer a segurança depois

    if ((this.isTec || this.isAdm || this.isAdmGlobal || this.isClient) && this.state.is2TalkAvaliable) {

      let system2TalkMenu = appMenuItems.push({
        name: this.props.intl.formatMessage({ id: "menu.2talk" }),
        Icon: QuestionAnswerIcon,
      });
      
      appMenuItems[system2TalkMenu - 1].items = [];

      if (!this.isClient) {
        appMenuItems[system2TalkMenu - 1].items.push({
          name: "Solicitações de registro de telefone",
          link: `/${this.props.match.params.lang}/phoneregistrationrequests`,
          state: "phoneregistrationrequests",
          disabled: !this.state.is2TalkAvaliable
        });
  
        appMenuItems[system2TalkMenu - 1].items.push({
          name: "Conversas",
          link: `/${this.props.match.params.lang}/chat-attendance-list`,
          state: "chats",
          disabled: !this.state.is2TalkAvaliable
        });
  
        appMenuItems[system2TalkMenu - 1].items.push({
          name: this.props.intl.formatMessage({ id: "menu.2talk.chatclassification" }),
          link: `/${this.props.match.params.lang}/chatclassifications`,
          state: "calledclassifications",
          disabled: !this.state.is2TalkAvaliable
        });
  
        if (this.isAdm || this.isAdmGlobal) {
          appMenuItems[system2TalkMenu - 1].items.push({
            name: this.props.intl.formatMessage({ id: "menu.2talk.channel" }),
            link: `/${this.props.match.params.lang}/channel`,
            state: "channels",
            disabled: !this.state.is2TalkAvaliable
          });
          
          appMenuItems[system2TalkMenu - 1].items.push({
            name: this.props.intl.formatMessage({ id: "menu.2talk.enableagents" }),
            link: `/${this.props.match.params.lang}/enableagents`,
            state: "enableagents",
            disabled: !this.state.is2TalkAvaliable
          });
  
          appMenuItems[system2TalkMenu - 1].items.push({
            name: this.props.intl.formatMessage({ id: "menu.2talk.rulesattendance" }),
            link: `/${this.props.match.params.lang}/rulesattendance`,
            state: "rulesattendance",
            disabled: !this.state.is2TalkAvaliable
          });
  
  
          appMenuItems[system2TalkMenu - 1].items.push({
            name: this.props.intl.formatMessage({ id: "menu.2talk.customer" }),
            link: `/${this.props.match.params.lang}/customers`,
            state: "customers",
            disabled: !this.state.is2TalkAvaliable
          });
  
          appMenuItems[system2TalkMenu - 1].items.push({
            name: this.props.intl.formatMessage({ id: "menu.2talk.company" }),
            link: `/${this.props.match.params.lang}/companies`,
            state: "companies",
            disabled: !this.state.is2TalkAvaliable
          });
  
          appMenuItems[system2TalkMenu - 1].items.push({
            name: this.props.intl.formatMessage({ id: "menu.2talk.webchat" }),
            link: `/${this.props.match.params.lang}/webchats`,
            state: "webchats",
            disabled: !this.state.is2TalkAvaliable
          });

          appMenuItems[system2TalkMenu - 1].items.push({
            name: this.props.intl.formatMessage({ id: "menu.2talk.chatbots" }),
            link: `/${this.props.match.params.lang}/chatbots`,
            state: "chatbots",
            disabled: !this.state.is2TalkAvaliable
          });

          appMenuItems[system2TalkMenu - 1].items.push({
            name: this.props.intl.formatMessage({ id: "menu.2talk.quickresponses" }),
            link: `/${this.props.match.params.lang}/quick-responses`,
            state: "quickresponses",
            disabled: !this.state.is2TalkAvaliable
          });

          appMenuItems[system2TalkMenu - 1].items.push({
            name: this.props.intl.formatMessage({ id: "2talk.chat.report" }),
            link: `/${this.props.match.params.lang}/2talk-chat-report`,
            state: "2talkchatreport",
            disabled: !this.state.is2TalkAvaliable
          });


        }
      } else {
        appMenuItems[system2TalkMenu - 1].items.push({
          name: this.props.intl.formatMessage({ id: "menu.2talk.chatclassification" }),
          link: `/${this.props.match.params.lang}/chatclassifications`,
          state: "calledclassifications",
          disabled: !this.state.is2TalkAvaliable
        });
      }
    }

    if (this.isAdm) {

      appMenuItems.push({ link: 'divider' });


      if (this.context.can('List', 'CalledServiceSettings') || this.isAdm) {
        appMenuItems.push({
          name: this.props.intl.formatMessage({ id: "menu.called.service.settings" }),
          link: `/${this.props.match.params.lang}/calledservicesettings`,
          state: "calledservicesettings",
          Icon: DynamicFormIcon
        })
      }

      if (this.context.can('List', 'KnowledgeBase')) {
        appMenuItems.push(
          {
            name: this.props.intl.formatMessage({ id: "knowledgebase" }),
            link: `/${this.props.match.params.lang}/knowledgebases`,
            Icon: EmojiObjectsIcon,
            state: "knowledgebases",
          });
      }

      if (this.context.can('List', 'Group')) {
        appMenuItems.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.groups" }),
            link: `/${this.props.match.params.lang}/groups`,
            Icon: AccountBalanceWalletIcon,
            state: "groups",
          });
      }

      PapiroConsole.log("entrei como adm ")

      if (this.context.can('Edit', 'Organization')) {

        PapiroConsole.log("entrei como adm  2")


        var organizations = appMenuItems.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.organizations" }),
            Icon: AccountBalanceIcon
          });

        appMenuItems[organizations - 1].items = [];
      }

      if (appMenuItems[organizations - 1] && appMenuItems[organizations - 1].items != null) {
        if (this.context.can('List', 'Organization')) {
          PapiroConsole.log("entrei como adm  3")

          if (this.context.can('List', 'OrganizationCategory')) {
            appMenuItems[organizations - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.organization.categories" }),
                link: `/${this.props.match.params.lang}/organizationcategories`,
                //Icon: LocationCity,
                state: "organizationcategories",
              });
          }



          appMenuItems[organizations - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.calledlistdatas" }),
              link: `/${this.props.match.params.lang}/calledlistdatas`,
              //Icon: SettingsApplicationsIcon,
              state: "calledlistdatas",
            });
          appMenuItems[organizations - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "additional.called.cost.config" }),
              link: `/${this.props.match.params.lang}/additional-service-config`,
              //Icon: SettingsApplicationsIcon,
              state: "additionalservicesconfig",
            });
          appMenuItems[organizations - 1].items.push({
            name: this.props.intl.formatMessage({ id: "called.report.configs" }),
            link: `/${this.props.match.params.lang}/called-report-configs`,
            state: "calledreportconfigs",
          })
          if (this.context.can('List', 'OrganizationDepartament')) {
            appMenuItems[organizations - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.organizationdepartaments" }),
                link: `/${this.props.match.params.lang}/organizationdepartaments`,
                //Icon: LocationCity,
                state: "organizationdepartaments",
              });
          }
          if (this.context.can('List', 'OrganizationMaterial')) {
            appMenuItems[organizations - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.organizationmaterials" }),
                link: `/${this.props.match.params.lang}/organizationmaterials`,
                //Icon: LocationCity,
                state: "organizationmaterials",
              });
          }
          appMenuItems[organizations - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.organization" }),
              link: `/${this.props.match.params.lang}/organizations`,
              //Icon: AccountBalanceIcon,
              state: "organizations",
            });


          if (this.context.can('List', 'OrganizationPatrimony')) {
            appMenuItems[organizations - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.organizationpatrimonies" }),
                link: `/${this.props.match.params.lang}/organizationpatrimonies`,
                //Icon: LocationCity,
                state: "organizationpatrimonies",
              });
          }

          if (this.context.can('List', 'OrganizationSla')) {
            appMenuItems[organizations - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.organization.sla" }),
                link: `/${this.props.match.params.lang}/organizationslas`,
                //Icon: HomeWork,
                state: "organizationslas",
              });
          }











        }
      }

      // solução temporaria enquanto não libera o acesso do menu inteiro de organização para adm de org
      /* if(this.isAdmOrg){
   
         var organizations = appMenuItems.push(
           {
             name: this.props.intl.formatMessage({ id: "menu.organizations" }),
             Icon: AccountBalanceIcon
           });
        
         appMenuItems[organizations - 1].items = [];
   
         appMenuItems[organizations - 1].items.push(
           {
             name: this.props.intl.formatMessage({ id: "menu.organization" }),
             link: `/${this.props.match.params.lang}/organizations`,
             //Icon: AccountBalanceIcon,
             state: "organizations",
           });
   
       }*/



      if (this.context.can('List', 'User')) {
        var access = appMenuItems.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.access" }),
            Icon: GroupAddIcon
          });
        appMenuItems[access - 1].items = [];
      }

      if (appMenuItems[access - 1] && appMenuItems[access - 1].items != null) {
        if (this.context.can('List', 'User')) {
          appMenuItems[access - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.users-agents" }),
              link: `/${this.props.match.params.lang}/users`,
              //Icon: AccountBoxIcon,
              state: "users",
            });

          appMenuItems[access - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.users-client" }),
              link: `/${this.props.match.params.lang}/users-client`,
              //Icon: AccountBoxIcon,
              state: "users-client",
            });
        }
        if (this.context.can('List', 'Permission')) {
          appMenuItems[access - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.permissions" }),
              link: `/${this.props.match.params.lang}/permissions`,
              //Icon: FunctionsIcon,
              state: "permissions",
            });
        }

        if (this.context.can('List', 'Role')) {
          appMenuItems[access - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.roles" }),
              link: `/${this.props.match.params.lang}/roles`,
              //Icon: SupervisedUserCircleIcon,
              state: "roles",
            });
        }


      }

      if (this.context.can('List', 'TechinicalTeam')) {
        var servicegroup = appMenuItems.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.servicegroup" }),
            Icon: SpeakerGroupIcon
          });
        appMenuItems[servicegroup - 1].items = [];
      }

      if (appMenuItems[servicegroup - 1] && appMenuItems[servicegroup - 1].items != null) {
        if (this.context.can('List', 'TechinicalTeam')) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.techinicalteams" }),
              link: `/${this.props.match.params.lang}/techinicalteams`,
              //Icon: GroupIcon,
              state: "techinicalteams",
            });
        }
        if (this.context.can('List', 'TechinicalTeamOrganizationCategory')) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.techinicalteamorganizationcategories" }),
              link: `/${this.props.match.params.lang}/techinicalteamorganizationcategories`,
              //Icon: ScatterPlotIcon,
              state: "techinicalteamorganizationcategories",
            });
        }




        if (this.context.can('List', 'TechinicalTeamOrganization')) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "menu.techinicalteamorganizations" }),
              link: `/${this.props.match.params.lang}/techinicalteamorganizations`,
              //Icon: LocationCityIcon,
              state: "techinicalteamorganizations",
            });
        }
        if (this.isAdm == true || this.isAdmOrg == true || this.isAdmGlobal == true) {
          if (this.context.can('List', 'TechinicalTeamUser')) {
            if (appMenuItems[servicegroup - 1] && appMenuItems[servicegroup - 1].items != null) {
              appMenuItems[servicegroup - 1].items.push(
                {
                  name: this.props.intl.formatMessage({ id: "menu.techinicalteamusers" }),
                  link: `/${this.props.match.params.lang}/techinicalteamusers`,
                  //Icon: GroupWorkIcon,
                  state: "techinicalteamusers",
                });
            }
          }
        }


      }


      //colocar permissão de integração depois
      
      var servicegroup = appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "menu.integrations" }),
          Icon: IntegrationInstructionsIcon
        });
      appMenuItems[servicegroup - 1].items = [];

      if(this.isAdmGlobal){

        appMenuItems[servicegroup - 1].items.push(
          {
            name: intl.formatMessage({ id: "authentication"}),
            link: `/${this.props.match.params.lang}/authentication`,
            state: "authentication",
          });

          appMenuItems[servicegroup - 1].items.push(
             {
               name: intl.formatMessage({ id: "integration.service"}),
               link: `/${this.props.match.params.lang}/integration-service`,
               state: "integrationservice",
             });

          appMenuItems[servicegroup - 1].items.push(
            {
              name: intl.formatMessage({ id: "integration.user.variable.header"}),
              link: `/${this.props.match.params.lang}/variable`,
              state: "variables",
            });
  
          appMenuItems[servicegroup - 1].items.push({
            name: intl.formatMessage({ id: "params"}),
            link: `/${this.props.match.params.lang}/params`,
            state: "params",
          });

      }

      

      var servicegroup = appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "reports" }),
          Icon: AssignmentIcon
        });
      appMenuItems[servicegroup - 1].items = [];


      // relatórios aqui

      if (this.isAdm) {
        appMenuItems[servicegroup - 1].items.push(
          {
            name: intl.formatMessage({ id: "menu.users-agents" }),
            link: `/${this.props.match.params.lang}/agents-report`,
            //Icon: QueuePlayNext,
            state: "agentsreport",
          });
      }

      if (this.isAdm) {
        appMenuItems[servicegroup - 1].items.push(
          {
            name: intl.formatMessage({ id: "audit" }),
            link: `/${this.props.match.params.lang}/audit-report`,
            //Icon: QueuePlayNext,
            state: "audit",
          });
      }

      if (appMenuItems[servicegroup - 1] && appMenuItems[servicegroup - 1].items != null) {
        if (this.context.can('List', 'CalledReport')) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "called.report.header" }),
              link: `/${this.props.match.params.lang}/calledreports`,
              //Icon: QueuePlayNext,
              state: "calledreports",
            });
        }




        if (this.isAdm) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: intl.formatMessage({ id: "calleds.organizations.report" }),
              link: `/${this.props.match.params.lang}/calleds-organizations-report`,
              //Icon: QueuePlayNext,
              state: "calledsorganizationsreport",
            });
        }

        if (this.isAdm) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: intl.formatMessage({ id: "calleds.organizations.synthetic.report" }),
              link: `/${this.props.match.params.lang}/calleds-organizations-synthetic-report`,
              //Icon: QueuePlayNext,
              state: "calledsorganizationsyntheticsreport",
            });
        }



        if (this.isAdm) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: intl.formatMessage({ id: "calleds-origin-categories" }),
              link: `/${this.props.match.params.lang}/calleds-origin-categories-report`,
              //Icon: QueuePlayNext,
              state: "calledsorigincategoriesreport",
            });
        }
        if (this.context.can('List', 'MaterialReport')) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "material.report.header" }),
              link: `/${this.props.match.params.lang}/materialreports`,
              //Icon: QueuePlayNext,
              state: "materialReports",
            });
        }

        if (this.isAdm) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: intl.formatMessage({ id: "reopened.calleds.report" }),
              link: `/${this.props.match.params.lang}/reopened-calleds-report`,
              //Icon: QueuePlayNext,
              state: "reopenedcalledsreport",
            });
        }



        if (this.context.can('List', 'ChatReport')) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "report.chat" }),
              link: `/${this.props.match.params.lang}/chatreports`,
              //Icon: QueuePlayNext,
              state: "chatreports",
            });
        }

        if (this.context.can('List', 'ChecklistReport')) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "checklist.report" }),
              link: `/${this.props.match.params.lang}/checklistreports`,
              //Icon: QueuePlayNext,
              state: "checklistreports",
            });
        }

        appMenuItems[servicegroup - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "calledClassification.report.header" }),
            link: `/${this.props.match.params.lang}/called-classification-reports`,
            //Icon: QueuePlayNext,
            state: "calledClassificationReports",
          });

        if (this.context.can('List', 'CostReport')) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "cost.report" }),
              link: `/${this.props.match.params.lang}/costreports`,
              //Icon: QueuePlayNext,
              state: "costreports",
            });
        }

        if (this.context.can('List', 'DashboardReport')) {


          appMenuItems[servicegroup - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "report.dashboard" }),
              link: `/${this.props.match.params.lang}/dashboardreports`,
              //Icon: QueuePlayNext,
              state: "dashboardreports",
            });
        }


        if (this.context.can('List', 'SendMailReport')) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "sendmail.report.header" }),
              link: `/${this.props.match.params.lang}/sendmailreports`,
              //Icon: QueuePlayNext,
              state: "sendmailreports",
            });
        }
        appMenuItems[servicegroup - 1].items.push(
          {
            name: "Erros Reportados",
            link: `/${this.props.match.params.lang}/usererrorreport`,
            //Icon: QueuePlayNext,
            state: "userreporterros",
          });
        if (this.context.can('List', 'MaterialReport')) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: this.props.intl.formatMessage({ id: "report.fcr" }),
              link: `/${this.props.match.params.lang}/reportfcr`,
              //Icon: QueuePlayNext,
              state: "reportfcr",
            });
        }
        if (this.isAdm) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: intl.formatMessage({ id: "hours.reported" }),
              link: `/${this.props.match.params.lang}/hours-report`,
              //Icon: QueuePlayNext,
              state: "hoursreport",
            });
        }




        if (this.isAdm) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: intl.formatMessage({ id: "organizations.categories.report" }),
              link: `/${this.props.match.params.lang}/organizations-categories-report`,
              //Icon: QueuePlayNext,
              state: "organizationscategoriesreport",
            });
        }
        if (this.isAdm) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: intl.formatMessage({ id: "quantitative.calleds.report" }),
              link: `/${this.props.match.params.lang}/quantitative-calleds-report`,
              //Icon: QueuePlayNext,
              state: "quantitativecalledsreport",
            });
        }























        appMenuItems[servicegroup - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "sendsms.report.header" }),
            link: `/${this.props.match.params.lang}/sendsmsreports`,
            //Icon: QueuePlayNext,
            state: "sendsmsreports",
          });








        if (this.isAdm) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: intl.formatMessage({ id: "menu.techinicalteamusers" }),
              link: `/${this.props.match.params.lang}/techinicalteamusers-report`,
              //Icon: QueuePlayNext,
              state: "techinicalteamusersreport",
            });
        }

        if (this.isAdm) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: intl.formatMessage({ id: "menu.techinicalteams" }),
              link: `/${this.props.match.params.lang}/techinicalteam-report`,
              //Icon: QueuePlayNext,
              state: "techinicalteamreport",
            });
        }

        if (this.isAdm) {
          appMenuItems[servicegroup - 1].items.push(
            {
              name: intl.formatMessage({ id: "menu.users" }),
              link: `/${this.props.match.params.lang}/users-report`,
              //Icon: QueuePlayNext,
              state: "usersreport",
            });
        }







      }


      /*if(this.isAdmOrg || this.isOnlyCoordOrg){
        var configs = appMenuItems.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.configs" }),
            Icon: SettingsApplicationsIcon
          });
        appMenuItems[configs - 1].items = [];
        if(appMenuItems[configs - 1] && appMenuItems[configs - 1].items != null) {
          if (this.context.can('List', 'DefaultAccompaniment')) {
            if (!this.isAdmOrg) {
              appMenuItems[configs - 1].items.push(
                {
                  name: this.props.intl.formatMessage({ id: "default.actions" }),
                  link: `/${this.props.match.params.lang}/default-actions`,
                  //Icon: AvTimer,
                  state: "defaultactions",
                });
            }
          }
        }

      }*/
      if (this.isOnlyCoordOrg) {
        var configs = appMenuItems.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.configs" }),
            Icon: SettingsApplicationsIcon
          });
        appMenuItems[configs - 1].items = [];
        if (appMenuItems[configs - 1] && appMenuItems[configs - 1].items != null) {
          if (this.context.can('List', 'DefaultAccompaniment')) {
            if (!this.isAdmOrg) {
              appMenuItems[configs - 1].items.push(
                {
                  name: this.props.intl.formatMessage({ id: "default.actions" }),
                  link: `/${this.props.match.params.lang}/default-actions`,
                  //Icon: AvTimer,
                  state: "defaultactions",
                });
            }
          }
        }
      }
      if (this.context.can('List', 'WorkHour')) {
        var configs = appMenuItems.push(
          {
            name: this.props.intl.formatMessage({ id: "menu.configs" }),
            Icon: SettingsApplicationsIcon
          });
        appMenuItems[configs - 1].items = [];

        if (appMenuItems[configs - 1] && appMenuItems[configs - 1].items != null) {

          if (this.context.can('List', 'DefaultAccompaniment')) {

            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "default.actions" }),
                link: `/${this.props.match.params.lang}/default-actions`,
                //Icon: AvTimer,
                state: "defaultactions",
              });

          }

          if (this.context.can('List', 'Activity')) {

            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.activities" }),
                link: `/${this.props.match.params.lang}/activity-list`,
                //Icon: AvTimer,
                state: "activitys",
              });

          }



          if (this.context.can('List', 'Category')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.categories" }),
                link: `/${this.props.match.params.lang}/categories`,
                //Icon: CategoryIcon,
                state: "categories",
              });
          }

          if (this.context.can('List', 'Config')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.configs" }),
                link: `/${this.props.match.params.lang}/configs`,
                //Icon: SettingsApplicationsIcon,
                state: "configs",
              });
          }

          if (this.context.can('List', 'DistributionStrategy')) {

            if (!this.isAdmOrg) {
              appMenuItems[configs - 1].items.push(
                {
                  name: this.props.intl.formatMessage({ id: "menu.distributionstrategies" }),
                  link: `/${this.props.match.params.lang}/distributionstrategies`,
                  //Icon: LowPriority,
                  state: "distributionstrategies",
                });
            }
          }

          if (this.context.can('List', 'WorkHour')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.workhours" }),
                link: `/${this.props.match.params.lang}/workhours`,
                //Icon: AccessTime,
                state: "workhours",
              });
          }

          if (this.context.can('List', 'Holiday')) {

            if (!this.isAdmOrg) {
              appMenuItems[configs - 1].items.push(
                {
                  name: this.props.intl.formatMessage({ id: "menu.holiday" }),
                  link: `/${this.props.match.params.lang}/holidays`,
                  //Icon: AccessTime,
                  state: "holiday",
                });
            }
          }

          /*if (this.context.can('List', 'CalledFlow')) {
            if (!this.isAdmOrg) {
              appMenuItems[configs - 1].items.push(
                {
                  name: this.props.intl.formatMessage({ id: "menu.calledflows" }),
                  link: `/${this.props.match.params.lang}/calledflows`,
                  //Icon: Timeline,
                  state: "calledflows",
                });
            }
          }*/

          if (this.context.can('List', 'PatrimonyGroup')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.patrimonygroups" }),
                link: `/${this.props.match.params.lang}/patrimonygroups`,
                //Icon: AvTimer,
                state: "patrimonygroup",
              });
          }

          if (this.context.can('List', 'Material')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.materials" }),
                link: `/${this.props.match.params.lang}/materials`,
                //Icon: AvTimer,
                state: "materials",
              });
          }

          if (this.context.can('List', 'PatrimonyMaterial')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.patrimonymaterials" }),
                link: `/${this.props.match.params.lang}/patrimonymaterials`,
                //Icon: AvTimer,
                state: "patrimonymaterials",
              });
          }

          if (this.context.can('List', 'Segment')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "reasons" }),
                link: `/${this.props.match.params.lang}/reasons`,
                //Icon: AddToHomeScreenIcon,
                state: "reasons",
              });
          }

          if (this.context.can('List', 'OriginRequest')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.originrequests" }),
                link: `/${this.props.match.params.lang}/originrequests`,
                //Icon: TripOriginIcon,
                state: "originrequests",
              });
          }

          if (!this.isAdmOrg) {
            appMenuItems[configs - 1].items.push({
              name: this.props.intl.formatMessage({ id: "priorities" }),
              link: `/${this.props.match.params.lang}/priorities`,
              state: "priorities"
            })
          }

          if (this.context.can('List', 'SatisfactionSurvey')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "satisfaction.survey" }),
                link: `/${this.props.match.params.lang}/satisfaction-survey-list`,
                //Icon: AddToHomeScreenIcon,
                state: "satisfactionsurveys",
              });
          }

          if (this.context.can('List', 'Segment')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.segments" }),
                link: `/${this.props.match.params.lang}/segments`,
                //Icon: AddToHomeScreenIcon,
                state: "segments",
              });
          }

          if (this.context.can('List', 'CalledClosingStatus')) {
            if (!this.isAdmOrg) {
              appMenuItems[configs - 1].items.push(
                {
                  name: this.props.intl.formatMessage({ id: "calledclosingstatus" }),
                  link: `/${this.props.match.params.lang}/called-closing-statuses`,
                  state: "calledclosingstatus",
                });
            }
          }





          if (this.context.can('List', 'Sla')) {

            if (!this.isAdmOrg) {
              appMenuItems[configs - 1].items.push(
                {
                  name: this.props.intl.formatMessage({ id: "menu.slas" }),
                  link: `/${this.props.match.params.lang}/slas`,
                  //Icon: AcUnitIcon,
                  state: "slas",
                });
            }
          }

          if (this.context.can('List', 'Status')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.statuses" }),
                link: `/${this.props.match.params.lang}/statuses`,
                //Icon: StarIcon,
                state: "statuses",
              });
          }

        /*  if (this.context.can('List', 'CalledFlowStatus')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.calledflowstatuses" }),
                link: `/${this.props.match.params.lang}/calledflowstatuses`,
                //Icon: SwapCalls,
                state: "calledflowstatuses",
              });
          }*/

          if (this.context.can('List', 'PatrimonySubGroup')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.patrimonysubgroups" }),
                link: `/${this.props.match.params.lang}/patrimonysubgroups`,
                //Icon: AvTimer,
                state: "patrimonysubgroups",
              });
          }



          if (this.context.can('List', 'Type')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.called.types" }),
                link: `/${this.props.match.params.lang}/types`,
                //Icon: AdjustIcon,
                state: "types",
              });
          }



          if (this.context.can('List', 'SlaCountType')) {
            if (!this.isAdmOrg) {
              appMenuItems[configs - 1].items.push(
                {
                  name: this.props.intl.formatMessage({ id: "menu.slacounttypes" }),
                  link: `/${this.props.match.params.lang}/slacounttypes`,
                  //Icon: AvTimer,
                  state: "slacounttypes",
                });
            }
          }



          if (this.context.can('List', 'Config')) {
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.units" }),
                link: `/${this.props.match.params.lang}/units`,
                state: "units",
              });
          }
        }
      }
    }

    if (this.state.redirect) {
      return <Redirect push component={Link} to={location.pathname} />;
    }
    if (this.state.openSteps) {
      if (this.state.orgId != 0) {
        this.setState({ openSteps: false, orgId: 0 })
        return <Redirect push component={Link} to={{ pathname: "/pt/dashboard", state: { orgId: this.state.orgid } }} />
      }
    }

    var organizationName = "";
    var OrganizationListMenu = [];


    if (this.isAdmGlobal) {
      organizationName = <FormattedMessage id="Administração" />;
    } else if (this.isClient) {


      if (userSession?.user?.organizationusers?.length == 1) {
        for (let key in userSession.user.organizationusers) {
          if (userSession?.user?.organizationusers[key]?.organization != null)
            organizationName += userSession.user.organizationusers[key].organization.name + ", ";
        }
        organizationName = organizationName.substring(0, organizationName.length - 2)
      } else {
        let Obj = {
          groupName: "",
        }
        OrganizationListMenu.push(Obj);
        if (userSession?.user?.organizationusers != null) {
          for (let key in userSession.user.organizationusers) {
            if (userSession?.user?.organizationusers[key]?.organization != null) {
              let Obj = {
                groupName: userSession.user.organizationusers[key].organization.name,
              }
              OrganizationListMenu.push(Obj);
            }
          }
        }
      }

    } else if (this.isTec || this.isAdmOrg) {

      let Obj = {
        groupName: "",
      }
      OrganizationListMenu.push(Obj);

      if (userSession?.user?.organizationusers != null) {
        for (let key in userSession.user.organizationusers) {
          if (userSession?.user?.organizationusers[key]?.organization != null) {
            let Obj = {
              groupName: userSession.user.organizationusers[key].organization.name,
            }
            OrganizationListMenu.push(Obj)
          }
        }
      }
    }

    if (userSession?.user?.organization != null) {
      organizationName = userSession.user.organization.name;
    }



    const MenuGroup = (item) => (
      <GroupMenu group={item} />
    )
    const MenuGroup2 = (item) => (
      <GroupMenu2 group={item} />
    )
    /*
  const handleSteps = (id) => (
    //this.setState({ openSteps: true, orgId: id })
    
  )*/

    const handleSteps = (id) => {
      this.props.history.push({
        pathname: `/${this.props.match.params.lang}/steps`,
        state: { orgId: id }
      })
    };

    const handleTutorial = () => {
      this.getTutorial()
    }

    return (
      <ThemeProvider theme={primaryTheme}>
        <div>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: headerMenu.open,
            }, classes.appBarAction, {
              [classes.appBarActionShift]: actionsHeaderMenu.open
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, headerMenu.open && classes.hide)}

              >
                <Menu />
              </IconButton>
              <LogoSmall2 src={this.state.logo} />
              {!this.state.matches && <Typography variant="h6" className={classes.title}>
                <div className={classes.title} style={this.state.style}>


                  {OrganizationListMenu.length > 1 &&
                    <Tooltip title={intl.formatMessage({ id: "user.options" })} aria-label="add">
                      <IconButton color="inherit" onClick={this.openModalGroup}>
                        <PeopleAltIcon /> &nbsp;
                        {intl.formatMessage({ id: "menu.organizations" })}
                      </IconButton>
                    </Tooltip>
                  }

                  {OrganizationListMenu.length <= 1 && <div>
                    {this.isClient && (organizationName)}
                    {this.isAdmGlobal && (organizationName)}

                    {(!this.isTec || this.isAdmOrg) && !this.isAdmGlobal && OrganizationListMenu.map((item, key) => {
                      if (key != 0 && (OrganizationListMenu.length > 1 || OrganizationListMenu[1].length > 1)) {
                        return MenuGroup2(item);
                      } else if (key != 0) {
                        return item[0];
                      }
                    })}
                  </div>}

                </div>
              </Typography>}
              {this.state.matches && <span style={{ marginLeft: '10px' }}><UserMenuMobile organizationName={organizationName} /></span>}

              <StyledTextField
                style={{ marginRight: 10, borderColor: '#fff' }}
                label={intl.formatMessage({ id: "enter.the.called.number" })}
                inputProps={{ maxLength: 255 }}
                autoComplete='fname'
                variant='outlined'
                name='searchCalledId'
                onChange={this.handleSearchCalledId}
                value={this.state.searchCalledId}
                InputProps={{
                  inputComponent: this.NumberFormatCustom,
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter")
                    this.goToCalled()
                }} />

              <Tooltip title={intl.formatMessage({ id: "menu.reload.page" })} aria-label="add">

                <IconButton color="inherit"
                  aria-label="open drawer"
                  onClick={this.handleReload}
                  edge="start"
                >
                  <CachedIcon />
                </IconButton>


              </Tooltip>



              <Can I="Open" a="Called">
                <Tooltip title={intl.formatMessage({ id: "menu.called.qr.code" })} aria-label="add">

                  <IconButton color="inherit" onClick={this.QRCode}>
                    <DeveloperModeIcon />
                  </IconButton>

                </Tooltip>
              </Can>

              {(this.isAdm || this.isAdmGlobal || this.isAdmOrg) && (
                <Tooltip title={intl.formatMessage({ id: "menu.check.organization.email" })} aria-label="add">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.openCheckEmail}
                    style={{ padding: "5px" }} >
                    <MailIcon />
                  </IconButton>
                </Tooltip>
              )}


              <TransitionsModal isopen={this.state.modalopen} handleOpen={this.openModal} handleClose={this.closeModal}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12}>
                    <QRCodeReader width={300} handleClose={this.closeModal} />
                  </Grid>
                </Grid>
              </TransitionsModal>

              {this.state.openCheckEmail && (
                <Dialog open={this.state.openCheckEmail} onClose={this.closeCheckEmail} >
                  <DialogTitle id="form-dialog-title">{intl.formatMessage({ id: "check.email" })}</DialogTitle>
                  <DialogContent style={{ width: "600px", paddingTop: "8px" }}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: 'organization' })}
                      options={this.state.OrganizationList}
                      stateName='organizationCheckEmail'
                      name='organizationCheckEmail'
                      changeSelect={this.changeValuesCheckEmail}
                      style={{ width: "600px" }}
                      selected={this.state.organizationCheckEmail}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.closeCheckEmail} color="primary">
                      {intl.formatMessage({ id: "close" })}
                    </Button>
                    <Button onClick={this.checkEmail} color="secondary" variant="contained">
                      {intl.formatMessage({ id: "confirm" })}
                    </Button>
                  </DialogActions>
                </Dialog>
              )}

              {/* <TransitionsModal isopen={this.state.modalgroup} handleOpen={this.openModalGroup} handleClose={this.closeModalGroup}>
              <Grid style={{ maxWidth: '500px', maxHeight:'600px'}} container spacing={6}>
                <Grid item xs={12} sm={12}>
                  <Card className="background-title-search">
                    <CardContent>

                      <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={12} md={12}>
                          <Typography variant="h5">
                            {<FormattedMessage id="organization.par" />}
                          </Typography>
                        </Grid>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} style={{ marginLeft: '15px', fontSize: '15px',  maxHeight:'400px',overflow:'auto',  paddingRight:'30px'  }}>
                  {this.isClient && (organizationName)}
                  {this.isAdmGlobal && (organizationName)}
                  {!this.isAdmGlobal && (
                    <MyMaterialTable
                      style={{border: '1px solid blue' }}
                      title=""
                      columns={[
                        { title: intl.formatMessage({ id: "name" }), field: 'groupName' },
                      ]}
                      data={OrganizationListMenu}
                    />
                  )}
                </Grid>
              </Grid>
                    </TransitionsModal>*/}

              <Dialog
                open={this.state.modalgroup}
                onClose={this.closeModalGroup}

                maxWidth="md"
                fullWidth={false}

                scroll="body" >
                <DialogTitle >Organizações participantes</DialogTitle>
                <DialogContent >
                  <Grid container spacing={2} style={{ minWidth: "400px", maxHeight: '400px', overflowY: 'scroll' }}>

                    <Grid item xs={12} md={12}>
                      {this.isClient && (organizationName)}
                      {this.isAdmGlobal && (organizationName)}
                      {!this.isAdmGlobal && (
                        <MyMaterialTable

                          title=""
                          columns={[
                            { title: intl.formatMessage({ id: "name" }), field: 'groupName' },
                          ]}
                          data={OrganizationListMenu}
                        />
                      )}

                    </Grid>

                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeModalGroup} color="primary">
                    {intl.formatMessage({ id: "close" })}
                  </Button>
                </DialogActions>
              </Dialog>
              <NotificationMenu getItens={this.getItens} userSession={userSession} propsToRedirect={this.props} loading={this.LoadingNotification} setAlertNotification={this.setAlertNotification} />

              {!this.state.matches && <UserMenu />}
              <div className='reactFlagsSelectCustomHeader'>
                <ReactFlagsSelect
                  style={{ color: 'black' }}
                  countries={["BR", "US", "ES"]}
                  customLabels={{ BR: "PT-BR", US: "EN-US", ES: "ES" }}
                  placeholder={intl.formatMessage({ id: "select.language" })}
                  onSelect={this.selectLanguage}
                  selected={this.state.languageCode} />
              </div>
              {!this.state.matches && <UserInfo />}
              {(!this.state.matches && this.state.isNotClient) && <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleActionsDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, actionsHeaderMenu.open && classes.hide)}

              >
                <WidgetsIcon />
              </IconButton>}
            </Toolbar>
          </AppBar>

          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}

          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification}
          />

          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={headerMenu.open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <ThemeProvider theme={theme}>


                <IconButton onClick={this.handleDrawerClose} style={divStyle.day}>
                  <LogoSmall src={this.state.logo} /><ChevronLeft />
                </IconButton>

              </ThemeProvider>
            </div>
            <Divider />

            <AppMenu appMenuItems={appMenuItems} />

          </Drawer>
          <Drawer
            className={classes.drawerRight}
            variant="persistent"
            anchor="right"
            open={actionsHeaderMenu.open}
            classes={{
              paper: classes.drawerPaperRight,
            }}
          >
            <div className={classes.drawerHeader}>
              <ThemeProvider theme={theme}>
                <IconButton onClick={this.handleActionsDrawerClose} className={classes.fullWidthIconButtonWithSpaceBetween} style={divStyle.day}>
                  Ações Rápidas <ChevronLeft />
                </IconButton>
              </ThemeProvider>
            </div>
            <Divider />
            <Rightappmenu appMenuItems={actionsMenuItems} />
          </Drawer>

          {/*this.state.modalRatingOpen &&

            <ModalRating
              data={this.state.unratedCalledsListState}
              onClose={this.closeModalRating}
              requiredratingcalleds={false}
              organizationId={this.state.organizationIdRatingCalleds}
              createdUser={this.props.userSession && this.props.userSession.user && this.props.userSession.user.id  ? this.props.userSession.user.id : null}
              setLoading={this.setLoading}
              setMessage={this.setMessage}
              getFinishedCalleds={this.getItens}
              showMore={this.state.showMoreData}
              updateList={(newList) => this.setState({ unratedCalledsListState: newList })}
            //adm={this.AdmOrganization}
            />

          */}
          {this.modalRating}
        </div>
      </ThemeProvider>
    );
  }
}

HeaderMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  attNotification: PropTypes.array
};



const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  actionsHeaderMenu: state.actionsHeaderMenu,
  lastCalledsQueue: state.lastCalledsQueue,
  userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(Object.assign({}, headerMenuActions, actionsHeaderMenuActions, userSessionActions), dispatch);



export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(HeaderMenu))));
HeaderMenu.contextType = AbilityContext;